import React, { useState ,useEffect} from 'react';
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import Topbar2 from "./Topbar2";
import Navbar2 from "./Navbar2";
import DisplayLayout from "./DisplayLayout";
import { UnitProvider } from '../../contexts/UnitContext'


const Layout = () => {
  const [isOpen, setIsOpen] = useState(true); // Sidebar open by default

  useEffect(() => {
    const handleResize = () => {
      // Check screen size to adjust sidebar state
      if (window.innerWidth >= 1024) {
        setIsOpen(true); // Open by default on laptop (1024px and above)
      } else {
        setIsOpen(false); // Closed by default on mobile (below 1024px)
      }
    };

    // Call the function initially
    handleResize();

    // Add event listener on window resize
    window.addEventListener('resize', handleResize);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      <UnitProvider>
        {/* <Navbar />
        <Sidebar /> */}
       
        <div className="w-full block lg:hidden sm:block">
    <Topbar2 setIsOpen={setIsOpen} isOpen={isOpen}/>
    </div>
    
        <div className="flex">
  <div className="w-6/6 bg-gray-100 ">
  <Navbar2 setIsOpen={setIsOpen} isOpen={isOpen}        />
  </div>
  <div className="w-full bg-white ">
   {/* Display only on laptop/desktop */}
   <div className="hidden lg:block sm:hidden">
  
  <Navbar />
  </div>
  <DisplayLayout />
  </div>
</div>


     
     
 
      </UnitProvider>
    </>
  );
};

export default Layout;
