
export const loginSuccess = (accessToken) => ({
    type: 'LOGIN_SUCCESS',
    payload: accessToken,
    isAuthenticated: true,
  });
  
  export const loginFailure = (error) => ({
    type: 'LOGIN_FAILURE',
    payload: error,
    isAuthenticated: false,
  });
  
export const logout = () => ({
    type: 'LOGOUT',
    isAuthenticated: false,
  });