import React from 'react';
import { FiInfo, FiMoreHorizontal } from 'react-icons/fi';

const ComplianceCard = ({coloe}) => {
  return (
    <div className="bg-white p-4 rounded-lg shadow-lg flex flex-col justify-between">
      <div className="flex justify-between items-center mb-2">
        <div className="flex items-center">
          <span className="text-lg font-semibold text-black">Compliance Limit</span>
          <FiInfo className="ml-2 text-gray-500" />
        </div>
        <FiMoreHorizontal className="text-gray-500" />
      </div>
      <div>
        <div className="flex justify-between items-center mb-2">
          <span className="text-sm font-semibold text-black">1,712 kL</span>
          <span className="text-sm font-semibold text-black">2,000 kL</span>
        </div>
        <div className="h-4 rounded-full bg-gray-200">
          <div className={`h-full ${coloe} rounded-full`}style={{ width: '85%' }}></div>
        </div>
        <div className="flex justify-between mt-2 text-sm text-gray-500">
          <span>Limit Utilised</span>
          <span>Total Limit</span>
        </div>
      </div>
    </div>
  );
};

export default ComplianceCard;
