import React,{useState} from 'react';
import drainageImg from '../../assets/drainage-1.svg';
import waterPumpImg from '../../assets/water-pump-1.svg';
import iconImg from '../../assets/icon.svg';
import cardImg from '../../assets/card.svg';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { LineChart, Line ,AreaChart, Area} from 'recharts';

import Notifications from './Notifications';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';



const CustomDropdowns = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('All Meters');
  
    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };
  
    const selectOption = (option) => {
      setSelectedOption(option);
      setIsOpen(false);
    };
  
    return (
      <div className="relative inline-block w-full">
        {/* Custom Dropdown Button */}
        <div
          className="block w-full bg-white border border-gray-300 font-extrabold text-xs lg:text-lg  hover:border-gray-500 px-4 py-3 pr-8 rounded-full shadow leading-tight cursor-pointer"
          onClick={toggleDropdown}
        >
          {selectedOption}
        </div>
  
        {/* Dropdown List */}
        {isOpen && (
          <ul className="absolute z-10 w-full bg-white border border-gray-300 rounded-md shadow-lg max-h-48 overflow-auto text-xs lg:text-lg ">
            <li
              className="px-4 py-2 hover:bg-bluetextcolor cursor-pointer"
              onClick={() => selectOption('Meter 1')}
            >
              Meter 1
            </li>
            <li
              className="px-4 py-2 hover:bg-bluetextcolor cursor-pointer"
              onClick={() => selectOption('Meter 2')}
            >
              Meter 2
            </li>
            <li
              className="px-4 py-2 hover:bg-bluetextcolor cursor-pointer"
              onClick={() => selectOption('Meter 3')}
            >
              Meter 3
            </li>
          </ul>
        )}
      </div>
    );
  };


const AlaramLayout = () => {

  // Severity Cell Renderer function
  const severityCellRenderer = (params) => {
    const severity = params.value;
    let style = { color: 'black' }; // Default style
  
    if (severity === 'Critical') {
      style = { color: '#C83C36', backgroundColor:'#FFEEEE', border:'1px solid #FFEEEE',borderRadius:'5px',padding: '5px 15px 5px 15px', fontWeight: 'bold' };
    } else if (severity === 'Warning') {
      style = { color: 'orange', fontWeight: 'bold' };
    } else if (severity === 'Info') {
      style = { color: '#25832B', backgroundColor:'#F2FFF3', border:'1px solid #F2FFF3',borderRadius:'5px',padding: '5px 15px 5px 15px', fontWeight: 'bold'  };
    }


    return <span style={style}>{severity}</span>;
  };

      const rowData = [
        { time: '2 Mar 06:00:20', meterNo: 'PN01786243', plantNo: 1, type: 'Daily Consumption exceeded Alarm', severity: 'Critical' },
        { time: '2 Mar 06:00:20', meterNo: 'PN01786243', plantNo: 1, type: 'Daily Consumption exceeded Alarm', severity: 'Critical' },
        { time: '2 Mar 06:00:20', meterNo: 'PN01786243', plantNo: 1, type: 'Daily Consumption exceeded Alarm', severity: 'Critical' },
        { time: '2 Mar 06:00:20', meterNo: 'PN01786243', plantNo: 1, type: 'Daily Consumption exceeded Alarm', severity: 'Info' },
        { time: '2 Mar 06:00:20', meterNo: 'PN01786243', plantNo: 1, type: 'Daily Consumption exceeded Alarm', severity: 'Critical' },
        { time: '2 Mar 06:00:20', meterNo: 'PN01786243', plantNo: 1, type: 'Daily Consumption exceeded Alarm', severity: 'Critical' },
        { time: '2 Mar 06:00:20', meterNo: 'PN01786243', plantNo: 1, type: 'Daily Consumption exceeded Alarm', severity: 'Info' }
      ];

    
  
   
  const columnDefs = [
    { headerName: 'Time', field: 'time', sortable: false, filter: false, cellStyle: { fontWeight: 'bold', color: '#202224', textAlign: 'left' }, headerStyle: { fontWeight: 'bold', color: '#202224', textAlign: 'left' }},
    { headerName: 'Meter No', field: 'meterNo', sortable: false, filter: false, cellStyle: { fontWeight: 'bold', color: '#202224', textAlign: 'left' }, headerStyle: { fontWeight: 'bold', color: '#202224', textAlign: 'left' }},
    { headerName: 'Plant No', field: 'plantNo', sortable: false, filter: false, cellStyle: { fontWeight: 'bold', color: '#202224', textAlign: 'left' }, headerStyle: { fontWeight: 'bold', color: '#202224', textAlign: 'left' }},
    { headerName: 'Type', field: 'type', sortable: false, filter: false, cellStyle: { fontWeight: 'bold', color: '#202224', textAlign: 'left' }, headerStyle: { fontWeight: 'bold', color: '#202224', textAlign: 'left' }},
    { 
        headerName: 'Severity', 
        field: 'severity', 
        sortable: false, 
        filter: false, 
        cellRenderer: severityCellRenderer, // Use the custom renderer
        cellStyle: { fontWeight: 'bold', textAlign: 'left' } 
      },
  ];

  
  return (
    <div className="p-4 flex flex-col space-y-4" style={{backgroundColor:'#F5F6FA'}}>
{/* meter dashboard   */}
<div className=" flex flex-col items-center">
      <div className="w-full  p-2">
        <div className="flex justify-between items-center">
          <h2 className="text-lg lg:text-3xl font-bold text-gray-900">Alarams</h2>
          <div className="flex space-x-4">
            <div className="relative inline-block text-left">
            <button className="bg-yellow text-black px-4 py-1 lg:px-6 lg:py-2 text-xs lg:text-lg rounded-full flex font-bold items-center">
          
           Download Report
          </button>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.415 0.292496L6 4.8775L10.585 0.292496L12 1.7075L6 7.7075L0 1.7075L1.415 0.292496Z" fill="black"/>
                </svg>
                </div>
            </div>
            <div className="relative inline-block text-left">
            {/*   <select className="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded-full shadow leading-tight focus:outline-none focus:shadow-outline">
                <option>All Meters</option>
                <option>Meter 1</option>
                <option>Meter 2</option>
              </select> */}
              <CustomDropdowns/>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.415 0.292496L6 4.8775L10.585 0.292496L12 1.7075L6 7.7075L0 1.7075L1.415 0.292496Z" fill="black"/>
</svg>
    </div>
            </div>
          </div>
        </div>
      </div>
    </div>





   {/* AG Grid   */}

   <div className="p-4  pt-0 min-h-screen flex flex-col items-center">
   <div className="ag-theme-alpine rounded-2xl shadow-2xl" style={{ width: '100%', borderRadius: '18px' }}>
    <Notifications/>
    </div>
  <div className="w-full  pt-3 rounded-2xl shadow-2xl overflow-hidden">
    <div className="ag-theme-alpine rounded-2xl shadow-2xl" style={{ height: 400, width: '100%', borderRadius: '18px' }}>
      <AgGridReact 
        rowData={rowData} 
        columnDefs={columnDefs} 
        defaultColDef={{ flex: 1, minWidth: 150 }}
        pagination={true}
        paginationPageSize={8}
        gridOptions={{ suppressCellSelection: true }}
        suppressHorizontalScroll={false}
      />
    </div>
  
  </div>
</div>
    </div>
  );
};

export default AlaramLayout;


