import React from 'react';
import { FaSearch, FaBell, FaUser, FaSignOutAlt } from 'react-icons/fa';
import logo from '../../assets/logo-2-1.svg';
import searchIcon from './../../assets/search.svg';

import faders from './../../assets/faders.svg';
import manIcon from './../../assets/man-438081-960-720.svg';
const Topbar2 = ({ setIsOpen, isOpen }) => {
  return (
    <div className="flex items-center justify-between p-4 w-full bg-white shadow-md">
      {/* Left Section - Hamburger and Logo */}
      <div className="flex items-center">
        {/* Hamburger Menu */}
        <button   onClick={() => setIsOpen(!isOpen)} className="p-2 focus:outline-none lg:hidden">
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
          </svg>
        </button>
        
        {/* Logo */}
        <div className="flex items-center ml-0">
        <img src={logo} alt="Logo" className="w-auto" />
        
        </div>
      </div>

      {/* Right Section - Search, Help, Profile */}
      <div className="flex items-center space-x-1">
        {/* Search Icon */}
        <button className="p-1 focus:outline-none">
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.5">
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.1871 18.4744C17.8254 16.9282 19.5214 12.7255 17.9751 9.08739C16.4289 5.44928 12.226 3.75341 8.58771 5.29957C4.94942 6.84574 3.25347 11.0484 4.79971 14.6865C6.34595 18.3247 10.5488 20.0205 14.1871 18.4744Z" stroke="black" stroke-width="1.33004" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.4485 16.948L22 22.5" stroke="black" stroke-width="1.33004" stroke-linecap="round" stroke-linejoin="round"/>
</g>
</svg>
                 
        </button>
        
        {/* Help Icon */}
        <button className="p-1 focus:outline-none">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="12" cy="12" r="12" fill="#2B3C73"/>
                  <path d="M13.2 16.8C13.2 17.0373 13.1296 17.2693 12.9978 17.4667C12.8659 17.664 12.6785 17.8178 12.4592 17.9087C12.2399 17.9995 11.9987 18.0232 11.7659 17.9769C11.5331 17.9306 11.3193 17.8163 11.1515 17.6485C10.9836 17.4807 10.8694 17.2669 10.8231 17.0341C10.7768 16.8013 10.8005 16.5601 10.8913 16.3408C10.9822 16.1215 11.136 15.9341 11.3333 15.8022C11.5307 15.6704 11.7627 15.6 12 15.6C12.3183 15.6 12.6235 15.7264 12.8485 15.9515C13.0736 16.1765 13.2 16.4817 13.2 16.8ZM12 6C9.794 6 8 7.615 8 9.6V10C8 10.2122 8.08429 10.4157 8.23431 10.5657C8.38434 10.7157 8.58783 10.8 8.8 10.8C9.01217 10.8 9.21566 10.7157 9.36569 10.5657C9.51571 10.4157 9.6 10.2122 9.6 10V9.6C9.6 8.5 10.677 7.6 12 7.6C13.323 7.6 14.4 8.5 14.4 9.6C14.4 10.7 13.323 11.6 12 11.6C11.7878 11.6 11.5843 11.6843 11.4343 11.8343C11.2843 11.9843 11.2 12.1878 11.2 12.4V13.2C11.2 13.4122 11.2843 13.6157 11.4343 13.7657C11.5843 13.9157 11.7878 14 12 14C12.2122 14 12.4157 13.9157 12.5657 13.7657C12.7157 13.6157 12.8 13.4122 12.8 13.2V13.128C14.624 12.793 16 11.338 16 9.6C16 7.615 14.206 6 12 6Z" fill="white"/>
                  </svg>
        </button>

        {/* Profile Image */}
        <img src={manIcon} alt="User Avatar" className="w-8 h-8 rounded-full" />
                         
      </div>
    </div>
  );
};

export default Topbar2;