import React from 'react';
import { FiTrendingUp, FiExternalLink } from 'react-icons/fi';
import bg from '../../assets/bg.svg';
//import bgIcon from '../../assets/bg-icon.svg';
import line from '../../assets/line.svg';
import path from '../../assets/Path.svg';
import tracedImage from '../../assets/traced-image.svg';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import GaugeChart from './GaugeChart';
import { FiArrowUpRight } from 'react-icons/fi';

import reload from '../../assets/reload.png';
import MapSection from './MapSection';

import MapComponent from './MapComponent';
import MeterList from './MeterList';
import ComplianceCard from './ComplianceCard';

import ConsumptionTrend from './ConsumptionTrend';
import Widgetlap from './Widgetlap';
import Widgetmob from './Widgetmob';


const Dashboard = () => {
  return (
    <div className="lg:p-6 sm:p-2 flex flex-col space-y-4" style={{backgroundColor:'#F5F6FA'}}>






      
  <div className="w-full block lg:hidden sm:block">
 
  <Widgetmob/>


  </div>
   {/* Display only on Mobile */}
   <div className="hidden lg:block sm:hidden">
   <Widgetlap/>
  </div>

        <div className="grid  grid-cols-1 sm:grid-cols-4  gap-4 p-3">
        <div className="col-span-2">
        <ComplianceCard coloe="bg-green-700" />
        </div>



        <div className=" col-span-2">
        <ComplianceCard coloe="bg-red-700"/>
        </div>
        </div>

    {/* Map and meters */}


    <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 h-full p-3">
      <div className="h-[350px] md:h-auto md:w-1/2">
        <MapComponent className="h-full w-full" />
      </div>
      <div className="overflow-y-auto h-[350px] md:h-[350px] md:w-1/2">
        <MeterList />
      </div>
    </div>

 {/* Chart */}
    <div className=" flex flex-col space-y-4">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1">
       
        <ConsumptionTrend/>
      </div>
      </div>
      
    </div>
  );
};

export default Dashboard;
