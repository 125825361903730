import React, { useEffect, useState } from 'react';
import logo from '../../../logo/logo.png'
import { useDispatch, useSelector } from 'react-redux';
import authService from '../../../redux/serviecs/Auth-serviecs';
//import { Navigate } from 'react-router-dom';
import { useNavigate  } from 'react-router-dom';
import bg_login from '../../../login/login-b.png'
const Login = () => {

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

 

  const [loading, setLoading] = useState(true);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  useEffect(() => {
    if (isAuthenticated) {
      // Redirect to the dashboard
      // You need to define your route for the dashboard
      // For example, <Route path="/dashboard" component={Dashboard} />
      return window.location.href = "/dashboard"
    }
  }, [isAuthenticated]);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const error = useSelector(state => state.auth.error);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const history = useNavigate(); 
  const handleLogin = () => {
 
    authService.login(username, password, dispatch);
   
    
  };
 
  const [showOTP, setShowOTP] = useState(false);
  const [showPassword, setShowPassword] = useState(true);

  const showOTPForm = () => {
    setShowOTP(true);
    setShowPassword(false);
  };

  const showPasswordForm = () => {
    setShowPassword(true);
    setShowOTP(false);
  };
  //console.log(localStorage.getItem("accessToken"))

  
  return (


    <div className="h-screen flex flex-col justify-center items-center " style={{
      backgroundImage: `url("${bg_login}")`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}>
      <div className="mb-3 flex justify-end items-center">
      {/*   <img src={logo} width="40%" alt="" /> */}
      </div>
      <div>
        <div className="py-10 bg-white shadow-xl px-10 backdrop-blur-md rounded-2xl">
          <div className="flex flex-col gap-3 justify-center items-center mb-4">
            <h2 className="text-3xl text-black font-bold">Login</h2>
            <p className="text-gray-400">Monitoring every drop of water in realtime</p>
            
          </div>
          <div className='flex justify-center items-center mb-3'>
          <p className='text-red-500'>{error}</p>
          </div>
          
          <div>
            {!showOTP && !showPassword && (
              <div className="flex flex-col justify-center items-center gap-3">
                <button className="text-sm py-4 border-[1px] text-[#912fa5] rounded-lg w-full border-[#912fa5]" onClick={showPasswordForm}>Login with password</button>
                <span className="text-gray-400"> OR</span>
                <button className="text-sm py-4 border-[1px] text-[#912fa5] rounded-lg w-full border-[#912fa5]" onClick={showOTPForm}>Login with OTP</button>
              </div>
            )}
            {showOTP && (
              <div className="flex flex-col justify-center items-center gap-3">
                
                <div className="relative w-full">
                  <input type="email" id="hs-floating-input-email-value" className="peer p-4 block w-full border-gray-200 rounded-lg text-sm placeholder:text-transparent focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none bg-slate-50 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 focus:pt-6 focus:pb-2 [&:not(:placeholder-shown)]:pt-6 [&:not(:placeholder-shown)]:pb-2 autofill:pt-6 autofill:pb-2" placeholder="you@email.com" value="+91 7340111925" />
                  <label htmlFor="hs-floating-input-email-value" className="absolute top-0 start-0 p-4 h-full text-sm truncate pointer-events-none transition ease-in-out duration-100 border border-transparent dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none peer-focus:text-xs peer-focus:-translate-y-1.5 peer-focus:text-gray-500 peer-[:not(:placeholder-shown)]:text-xs peer-[:not(:placeholder-shown)]:-translate-y-1.5 peer-[:not(:placeholder-shown)]:text-gray-500">Phone Number</label>
                </div>
                <button className="text-sm py-4 border-[1px] text-[#912fa5] rounded-lg w-full border-[#912fa5]" onClick={showOTPForm}>Send OTP</button>
                <span className="text-gray-400">OR</span>
                <button className="text-[#912fa5]" onClick={showPasswordForm}>Login with Password</button>
              </div>
            )}
            {showPassword && (
              <div className="flex flex-col justify-center items-center gap-3">
                <div class="relative w-full">
                  <input class="peer p-4 block w-full border-gray-200 rounded-lg text-sm placeholder:text-transparent focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:focus:ring-neutral-600
    focus:pt-6
    focus:pb-2
    [&:not(:placeholder-shown)]:pt-6
    [&:not(:placeholder-shown)]:pb-2
    autofill:pt-6
    autofill:pb-2" placeholder="********" type="text"
                    id="username hs-floating-input-passowrd"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)} />
                  <label for="hs-floating-input-passowrd" class="absolute top-0 start-0 p-4 h-full text-sm truncate pointer-events-none transition ease-in-out duration-100 border border-transparent  origin-[0_0] dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none
      peer-focus:scale-90
      peer-focus:translate-x-0.5
      peer-focus:-translate-y-1.5
      peer-focus:text-gray-500 dark:peer-focus:text-neutral-500
      peer-[:not(:placeholder-shown)]:scale-90
      peer-[:not(:placeholder-shown)]:translate-x-0.5
      peer-[:not(:placeholder-shown)]:-translate-y-1.5
      peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500 dark:text-neutral-500">Username</label>
                </div>
                <div className="relative w-full">
      <input
        className="peer p-4 block w-full border-gray-200 rounded-lg text-sm placeholder:text-transparent focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:focus:ring-neutral-600
        focus:pt-6
        focus:pb-2
        [&:not(:placeholder-shown)]:pt-6
        [&:not(:placeholder-shown)]:pb-2
        autofill:pt-6
        autofill:pb-2"
        placeholder="********"
        type={isPasswordVisible ? 'text' : 'password'}
        id="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <label
        htmlFor="password"
        className="absolute top-0 start-0 p-4 h-full text-sm truncate pointer-events-none transition ease-in-out duration-100 border border-transparent  origin-[0_0] dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none
        peer-focus:scale-90
        peer-focus:translate-x-0.5
        peer-focus:-translate-y-1.5
        peer-focus:text-gray-500 dark:peer-focus:text-neutral-500
        peer-[:not(:placeholder-shown)]:scale-90
        peer-[:not(:placeholder-shown)]:translate-x-0.5
        peer-[:not(:placeholder-shown)]:-translate-y-1.5
        peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500 dark:text-neutral-500">
        Password
      </label>
      <button
        type="button"
        className="absolute top-0 right-0 p-4 text-sm dark:text-white"
        onClick={togglePasswordVisibility}
      >
        <img
          src={isPasswordVisible ? 'https://img.icons8.com/fluency-systems-regular/48/hide.png' : 'https://img.icons8.com/fluency-systems-regular/48/visible--v1.png '}
          alt={isPasswordVisible ? 'Hide password' : 'Show password'}
           width="20" height="20"
        />
      </button>
    </div>
                <button className="text-sm py-4 border-[1px] text-[#912fa5] rounded-lg w-full border-[#912fa5]" onClick={handleLogin}>Login</button>

                <span className="text-gray-400" hidden>OR</span>
                <button hidden className="text-[#912fa5]" onClick={showOTPForm}>
                  Login with OTP
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
