import React, { useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import greenMarkerUrl from '../../assets/greenmarker.svg'; // Replace with your actual path
import redMarkerUrl from '../../assets/redmarker.svg'; // Replace with your actual path
//import './MapComponent.css'; // Custom styles for popups and map elements

// Custom icons for green and red markers
const greenIcon = new L.Icon({
  iconUrl: greenMarkerUrl,
  iconSize: [32, 32],
  iconAnchor: [16, 32],
  popupAnchor: [0, -32],
});

const redIcon = new L.Icon({
  iconUrl: redMarkerUrl,
  iconSize: [32, 32],
  iconAnchor: [16, 32],
  popupAnchor: [0, -32],
});
const Popups = ({ onClose, marker }) => {
  return (
      <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white shadow-md rounded-lg p-4 max-w-sm w-full relative">
              <button
                  onClick={onClose}
                  className="absolute top-2 right-2 text-blue-500"
              >
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="w-6 h-6"
                  >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
              </button>
              <h1 className="text-xl font-semibold mb-2">{marker.info}</h1>
              <hr className="border-gray-300 my-2" />
              <p className="text-gray-500 mb-1">Thursday, 11 Nov 2021 | 8:00AM to Friday, 12 Nov 2021 | 8:00 AM</p>
              <p className="text-4xl text-blue-600 font-bold">68.2 KL</p>
          </div>
      </div>
  );
};
const MapComponent = () => {
  const [selectedMarker, setSelectedMarker] = useState(null);

  const markers = [
    { id: 1, position: [12.9986, 77.5898], info: 'Marker 1 Info', icon: greenIcon },
    { id: 2, position: [12.9534, 77.5830], info: 'Marker 2 Info', icon: redIcon },
    { id: 3, position: [12.9719, 77.5884], info: 'Marker 3 Info', icon: greenIcon },
  ];

  return (
    <div className="map-container" style={{ height: '350px', borderRadius: '10px' }}>
      <MapContainer center={[12.9716, 77.5946]} zoom={15} style={{ height: '100%', width: '100%' }}  attributionControl={false}>
       
      <style>
        {`
          .grayscale-map {
            filter: grayscale(100%);
          }
          .custom-popup .leaflet-popup-content-wrapper {
            background-color: #fff;
            border-radius: 10px;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
            padding: 10px;
          }
          .popup-content h4 {
            margin: 0;
            font-size: 1.2em;
            font-weight: bold;
          }
          .popup-content .popup-data {
            font-size: 1em;
            color: #333;
            font-weight: bold;
          }
          .leaflet-popup-content {
    margin: 0px;
    line-height: 1.3;
    font-size: 13px;
    font-size: 1.08333em;
    min-height: 1px;
}
          .leaflet-control-attribution {
            display: none !important;
          }
          .leaflet-popup-content p {
            margin: 17px 0;
            margin: .3em 0;
            font-size: 8px;
        }
    p .mapv{
  font-size: x-large;
  color: #2B3C73;
  font-weight: 600;
}
        `}
      </style>
      <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          
          className="grayscale-map"
        />
        {markers.map(marker => (
          <Marker
            key={marker.id}
            position={marker.position}
            icon={marker.icon}
            eventHandlers={{
                
                mouseover: (e) => {
                  setSelectedMarker(marker);
                  e.target.openPopup();  // Open the popup on hover
                },
                mouseout: (e) => {
                  e.target.closePopup();  // Close the popup when not hovering
                },
              }}
          >
            {selectedMarker && selectedMarker.id === marker.id && (
              <Popup onClose={() => setSelectedMarker(null)} className="custom-popup">

              <div className="popup-content ">
          <div className="">
              <button
               
                  className="absolute top-2 right-2 text-blue-500"
              >
                 <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.76645 0.114487C8.26618 0.364395 8.28378 1.12988 8.79472 1.34335C8.96972 1.41649 9.32979 1.43913 10.3175 1.43913H11.611L7.20263 5.84921L2.79437 10.2593L2.8316 10.5368C2.88014 10.8988 3.1012 11.1198 3.46317 11.1684L3.74069 11.2056L8.15078 6.79736L12.5609 2.38904V3.68254C12.5609 5.19023 12.6039 5.37216 13.0001 5.53853C13.2161 5.6292 13.2812 5.63103 13.5095 5.55253C13.9885 5.38773 13.9999 5.32441 14 2.82723C14.0001 1.06342 13.9819 0.606321 13.9044 0.420657C13.7354 0.0163559 13.6282 -0.00013009 11.1728 7.51414e-07C9.23088 6.61723e-05 8.97063 0.0124307 8.76645 0.114487ZM0.406509 2.19062C-0.0134277 2.37556 4.90247e-05 2.18146 4.90247e-05 8.04663C4.90247e-05 12.5547 0.0144417 13.3843 0.0958253 13.5791C0.278219 14.0157 0.0590586 13.9999 5.95335 13.9999C11.8477 13.9999 11.6285 14.0157 11.8109 13.5791C11.9491 13.2484 11.9532 7.63029 11.8155 7.30077C11.6208 6.83504 11.0943 6.71996 10.7138 7.05989L10.5001 7.25072L10.4809 9.9057L10.4618 12.5607H5.95054H1.43931V8.04945V3.53816L4.09429 3.51905L6.74927 3.49988L6.9401 3.28622C7.20401 2.99071 7.20401 2.63515 6.9401 2.33971L6.74927 2.12605L3.68541 2.11093C1.12921 2.09837 0.585894 2.11152 0.406509 2.19062Z" fill="#2B3C73"/>
</svg>

              </button>
              <h1 className="text-xl font-semibold mb-2">{marker.info}</h1>
              <hr className="border-gray-300 my-2" />
              <p className="text-gray-500 mb-1">Thursday, 11 Nov 2021 | 8:00AM to Friday, 12 Nov 2021 | 8:00 AM</p>
              <span className=" font-bold  mapv">68.2 KL</span>
          </div>
      </div>
                {/* <div className="popup-content">
                  <h4>{marker.info}</h4>
                  <p>Date range: Thursday, 11 Nov 2021 to Friday, 12 Nov 2021</p>
                  <p className="popup-data">68.2 KL</p>
                </div> */}
              </Popup>
            )}
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
};

export default MapComponent;
