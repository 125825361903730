import React, { useState ,useEffect} from 'react';
import logo from '../../assets/logo-2-1.svg';
import more from '../../assets/More.svg';
import comp from '../../assets/comp.png';
import dash from '../../assets/dashboard.svg';
import ana from '../../assets/ana.svg';
import met from '../../assets/met.svg';
import rep from '../../assets/rep.svg';
import alaram from '../../assets/alaram.svg';
import support from '../../assets/support.svg';
import setting from '../../assets/setting.svg';
import logout from '../../assets/logout.svg';
import Topbar2 from "./Topbar2";
const Navbar2 = ({ setIsOpen, isOpen }) => {
  const [isAnalyticsOpen, setIsAnalyticsOpen] = useState(false);
  return (
    <>
     
   
    <div className={`flex ${isOpen ? 'w-64' : 'w-0'} bg-white textblue h-screen transition-all duration-300`} style={{ borderRight: '1px solid #E0E0E0' }}>
      <div className="flex flex-col items-center w-full">
        {/* Sidebar Header */}
        <div className="flex justify-between items-center w-full p-4">
          {/* Logo on the left */}
          <div>
            <img src={logo} alt="Logo" className="w-auto" />
          </div>

          {/* Collapse button */}
          <div className="relative" hidden>
            <span onClick={() => setIsOpen(!isOpen)} className="cursor-pointer text-lg">
              <img src={more} alt="Toggle Sidebar" />
            </span>
          </div>
        </div>

        {/* Sidebar Links */}
        <div className="flex flex-col items-start w-full">
          <div className="border-left-custom relative-container w-full">
          

          <a href="dashboard" className="flex items-center p-4 hover:bgprima mx-2">

<img src={dash} className="svg-hover w-auto" alt="Dashboard Icon" />

<span className={`${isOpen ? 'ml-4' : 'hidden'} whitespace-nowrap`}>Dashboard</span>

</a>
          </div>

          {/* Analytics */}
          <div className="border-left-custom relative-container w-full">
            <a href="#" onClick={() => setIsAnalyticsOpen(!isAnalyticsOpen)} className="flex items-center p-4 hover:bgprima mx-2">
              <img src={ana} className="svg-hover w-auto" alt="Analytics Icon" />
              <span className={`${isOpen ? 'ml-4' : 'hidden'} whitespace-nowrap`}>Analytics</span>
              <i className={`fas fa-chevron-${isAnalyticsOpen ? 'down' : 'right'} ml-auto`} />
            </a>
            <div className={`${isAnalyticsOpen ? 'block' : 'hidden'} ml-8`}>
              <a href="/analytics" className="block py-2 px-4 text-sm hover:textblue">Daily</a>
              <a href="/analytics" className="block py-2 px-4 text-sm hover:textblue">Monthly</a>
            </div>
          </div>

          {/* Other links */}
          <div className="border-left-custom relative-container w-full">
            <a href="/reports" className="flex items-center p-4 hover:bgprima mx-2">
              <img src={rep} className="svg-hover w-auto" alt="Reports Icon" />
              <span className={`${isOpen ? 'ml-4' : 'hidden'} whitespace-nowrap`}>Reports</span>
            </a>
          </div>

          <div className="border-left-custom relative-container w-full">
            <a href="/meters" className="flex items-center p-4 hover:bgprima mx-2">
              <img src={met} className="svg-hover w-auto" alt="Meters Icon" />
              <span className={`${isOpen ? 'ml-4' : 'hidden'} whitespace-nowrap`}>Meters</span>
            </a>
          </div>

          <div className="border-left-custom relative-container w-full">
            <a href="/alarams" className="flex items-center p-4 hover:bgprima mx-2">
              <img src={alaram} className="svg-hover w-auto" alt="Alarms Icon" />
              <span className={`${isOpen ? 'ml-4' : 'hidden'} whitespace-nowrap`}>Alarms</span>
            </a>
          </div>

          <div className="border-left-custom relative-container w-full">
            <a href="/support" className="flex items-center p-4 hover:bgprima mx-2">
              <img src={support} className="svg-hover w-auto" alt="Support Icon" />
              <span className={`${isOpen ? 'ml-4' : 'hidden'} whitespace-nowrap`}>Support</span>
            </a>
          </div>
        </div>

        {/* Sidebar Footer */}
        <div className="mt-auto">
          <div className="bg-blue-100 p-4 m-4 rounded-lg text-center mb-6">
            <p className="text-sm">Welcome to Dhaara Live Dashboard</p>
            <img src={comp} alt="Dashboard" className="mt-2 mb-2 w-full" />
            <button className="bg-bluecolor text-black px-4 py-2 rounded-full mt-2">Learn more</button>
          </div>
        </div>

        <div className="border-left-custom relative-container w-full">
          <a href="/settings" className="flex items-center p-4 hover:bgprima mx-2">
            <img src={setting} className="svg-hover w-auto" alt="Settings Icon" />
            <span className={`${isOpen ? 'ml-4' : 'hidden'} whitespace-nowrap`}>Settings</span>
          </a>
        </div>

        <div className="border-left-custom relative-container w-full">
          <a href="/logout" className="flex items-center p-4 hover:bgprima mx-2">
            <img src={logout} className="svg-hover w-auto" alt="Logout Icon" />
            <span className={`${isOpen ? 'ml-4' : 'hidden'} whitespace-nowrap`}>Logout</span>
          </a>
        </div>
      </div>
    </div>
    </>
  );
};

export default Navbar2;

