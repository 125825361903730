import React ,{useState} from 'react';
import drainageImg from '../../assets/drainage-1.svg';
import waterPumpImg from '../../assets/water-pump-1.svg';
import iconImg from '../../assets/icon.svg';
import cardImg from '../../assets/card.svg';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { LineChart, Line ,AreaChart, Area} from 'recharts';



import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';


const CustomHorizontalLines = (props) => {
  const { yAxis, width, height } = props;
  const data = yAxis ? yAxis.getTicks() : [];
  
  return (
    <g>
      {data.map((entry) => (
        <line
          key={`line-${entry.value}`}
          x1={0}
          y1={yAxis.toGlobalCoordinate(entry.value)}
          x2={width}
          y2={yAxis.toGlobalCoordinate(entry.value)}
          stroke="#ddd"
          strokeWidth={1}
        />
      ))}
    </g>
  );
};


const CustomGridLines = ({ x, y, width, height }) => {
  const ticks = [10, 20, 30, 40, 50]; // Customize based on your Y-axis scale

  return (
    <g>
      {ticks.map((tick) => (
        <line
          key={tick}
          x1={0}
          y1={y(tick)}
          x2={width}
          y2={y(tick)}
          stroke="#ddd"
          strokeDasharray="3 3"
        />
      ))}
    </g>
  );
};


const CustomDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Plant 1');

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  return (
    <div className="relative inline-block w-full">
      {/* Custom Dropdown Button */}
      <div
        className="block w-full bg-white border border-gray-300 font-extrabold hover:border-gray-500 px-4 py-3 pr-8 lg:text-lg sm:text-xxs rounded-full shadow leading-tight cursor-pointer"
        onClick={toggleDropdown}
      >
        {selectedOption}
      </div>

      {/* Dropdown List */}
      {isOpen && (
        <ul className="absolute z-10 w-full bg-white border border-gray-300 rounded-md shadow-lg max-h-48 overflow-auto">
          <li
            className="px-4 py-2  lg:text-lg sm:text-xxs hover:bg-bluetextcolor cursor-pointer"
            onClick={() => selectOption('Plant 1')}
          >
            Plant 1
          </li>
          <li
            className="px-4 py-2 lg:text-lg sm:text-xxs hover:bg-bluetextcolor cursor-pointer"
            onClick={() => selectOption('Plant 2')}
          >
            Plant 2
          </li>
          <li
            className="px-4 py-2 lg:text-lg sm:text-xxs hover:bg-bluetextcolor cursor-pointer"
            onClick={() => selectOption('Plant 3')}
          >
            Plant 3
          </li>
        </ul>
      )}
    </div>
  );
};

const CustomDropdowns = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('All Meters');

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  return (
    <div className="relative inline-block w-full">
      {/* Custom Dropdown Button */}
      <div
        className="block w-full bg-white border border-gray-300 font-extrabold hover:border-gray-500   px-4 py-3 pr-8 lg:text-lg sm:text-xxs rounded-full shadow leading-tight cursor-pointer"
        onClick={toggleDropdown}
      >
        {selectedOption}
      </div>

      {/* Dropdown List */}
      {isOpen && (
        <ul className="absolute z-10 lg:text-lg sm:text-xxs w-full bg-white  border border-gray-300 rounded-md shadow-lg max-h-48 overflow-auto">
          <li
            className=" lg:px-4 lg:py-2 px-4 py-2  lg:text-lg sm:text-xxs hover:bg-bluetextcolor cursor-pointer"
            onClick={() => selectOption('Meter 1')}
          >
            Meter 1
          </li>
          <li
            className=" lg:px-4 lg:py-2 px-4 py-2  lg:text-lg sm:text-xxs  hover:bg-bluetextcolor cursor-pointer"
            onClick={() => selectOption('Meter 2')}
          >
            Meter 2
          </li>
          <li
            className=" lg:px-4 lg:py-2 px-4 py-2  lg:text-lg sm:text-xxs hover:bg-bluetextcolor cursor-pointer"
            onClick={() => selectOption('Meter 3')}
          >
            Meter 3
          </li>
        </ul>
      )}
    </div>
  );
};
const CustomDropdownss = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Last 12 Months');

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  return (
    <div className="relative inline-block w-full">
      {/* Custom Dropdown Button */}
      <div
        className="block w-full bg-white border border-gray-300 font-extrabold hover:border-gray-500 px-4 py-3 pr-8 rounded-full shadow leading-tight cursor-pointer"
        onClick={toggleDropdown}
      >
        {selectedOption}
      </div>

      {/* Dropdown List */}
      {isOpen && (
        <ul className="absolute z-10 w-full bg-white border border-gray-300 rounded-md shadow-lg max-h-48 overflow-auto">
          <li
            className="px-4 py-2 hover:bg-bluetextcolor cursor-pointer"
            onClick={() => selectOption('Last 12 months')}
          >
            Last 12 months
          </li>
          <li
            className="px-4 py-2 hover:bg-bluetextcolor cursor-pointer"
            onClick={() => selectOption('Last 6 months')}
          >
          Last 6 months
          </li>
          <li
            className="px-4 py-2 hover:bg-bluetextcolor cursor-pointer"
            onClick={() => selectOption('Last 3 months')}
          >
           Last 3 months
          </li>
        </ul>
      )}
    </div>
  );
};
const ReportLayout = () => {

    const data = [
        { month: 'Jan-24', value: 120 },
        { month: 'Feb-24', value: 60 },
        { month: 'Mar-24', value: 90 },
        { month: 'Apr-24', value: 30 },
        { month: 'May-24', value: 90 },
        { month: 'Jun-24', value: 60 },
        { month: 'Jul-24', value: 60 },
        { month: 'Aug-24', value: 30 },
        { month: 'Sep-24', value: 0 },
        { month: 'Oct-24', value: 90 },
        { month: 'Nov-24', value: 60 },
        { month: 'Dec-24', value: 60 },
      ];
      const data1= [
        { month: 'Jun-23', hours: 5 },
        { month: 'Jul-23', hours: 6 },
        { month: 'Aug-23', hours: 8 },
        { month: 'Sep-23', hours: 7 },
        { month: 'Oct-23', hours: 10 },
        { month: 'Nov-23', hours: 5 },
        { month: 'Dec-23', hours: 12 },
        { month: 'Jan-24', hours: 15 },
        { month: 'Feb-24', hours: 8 },
        { month: 'Mar-24', hours: 10 },
        { month: 'Apr-24', hours: 6 },
        { month: 'May-24', hours: 15 },
      ];
      const data2 = [
        { month: 'Jun-23', flowRate: 5 },
        { month: 'Jul-23', flowRate: 6 },
        { month: 'Aug-23', flowRate: 8 },
        { month: 'Sep-23', flowRate: 7 },
        { month: 'Oct-23', flowRate: 10 },
        { month: 'Nov-23', flowRate: 5 },
        { month: 'Dec-23', flowRate: 12 },
        { month: 'Jan-24', flowRate: 15 },
        { month: 'Feb-24', flowRate: 8 },
        { month: 'Mar-24', flowRate: 10 },
        { month: 'Apr-24', flowRate: 6 },
        { month: 'May-24', flowRate: 15 },
      ];
      




      // Sample data
const rowData = [
    { startEndDate: '1 Mar 2024 - 1 Apr 2024', workHours: '17 hr 3 min', flowRate: 136.76, consumption: 2332.51, totalizer: 267332.51 },
    { startEndDate: '1 Mar 2024 - 1 Apr 2024', workHours: '17 hr 3 min', flowRate: 136.76, consumption: 2332.51, totalizer: 267332.51 },
    { startEndDate: '1 Mar 2024 - 1 Apr 2024', workHours: '17 hr 3 min', flowRate: 136.76, consumption: 2332.51, totalizer: 267332.51 },
    { startEndDate: '1 Mar 2024 - 1 Apr 2024', workHours: '17 hr 3 min', flowRate: 136.76, consumption: 2332.51, totalizer: 267332.51 },
    { startEndDate: '1 Mar 2024 - 1 Apr 2024', workHours: '17 hr 3 min', flowRate: 136.76, consumption: 2332.51, totalizer: 267332.51 },
    { startEndDate: '1 Mar 2024 - 1 Apr 2024', workHours: '17 hr 3 min', flowRate: 136.76, consumption: 2332.51, totalizer: 267332.51 },
    { startEndDate: '1 Mar 2024 - 1 Apr 2024', workHours: '17 hr 3 min', flowRate: 136.76, consumption: 2332.51, totalizer: 267332.51 },
    { startEndDate: '1 Mar 2024 - 1 Apr 2024', workHours: '17 hr 3 min', flowRate: 136.76, consumption: 2332.51, totalizer: 267332.51 },
  ];
  
   
  const columnDefs = [
    { headerName: 'Start - End Date', field: 'startEndDate', sortable: false, filter: false, cellStyle: { fontWeight: 'bold', color: '#202224', textAlign: 'left' }, headerStyle: { fontWeight: 'bold', color: '#202224', textAlign: 'left' }},
    { headerName: 'Work Hours', field: 'workHours', sortable: false, filter: false, cellStyle: { fontWeight: 'bold', color: '#202224', textAlign: 'left' }, headerStyle: { fontWeight: 'bold', color: '#202224', textAlign: 'left' }},
    { headerName: 'Flow Rate (kL/hr)', field: 'flowRate', sortable: false, filter: false, cellStyle: { fontWeight: 'bold', color: '#202224', textAlign: 'left' }, headerStyle: { fontWeight: 'bold', color: '#202224', textAlign: 'left' }},
    { headerName: 'Consumption (kL)', field: 'consumption', sortable: false, filter: false, cellStyle: { fontWeight: 'bold', color: '#202224', textAlign: 'left' }, headerStyle: { fontWeight: 'bold', color: '#202224', textAlign: 'left' }},
    { headerName: 'Totalizer (kL)', field: 'totalizer', sortable: false, filter: false, cellStyle: { fontWeight: 'bold', color: '#202224', textAlign: 'left' }, headerStyle: { fontWeight: 'bold', color: '#202224', textAlign: 'left' }},
  ];

  return (
    <div className="p-4 flex flex-col space-y-4" style={{backgroundColor:'#F5F6FA'}}>
{/* meter dashboard   */}
<div className=" flex flex-col items-center dashbpaddings  pb-0">
        <div className="w-full  p-2">
          <div className="flex justify-between items-center">
            <h2 className="text-xl lg:text-3xl sm:text-xl font-bold text-gray-900">
            Reports
            </h2>
            <div className="flex space-x-4">
              <div className="relative inline-block text-left">
                <CustomDropdown />
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.415 0.292496L6 4.8775L10.585 0.292496L12 1.7075L6 7.7075L0 1.7075L1.415 0.292496Z"
                      fill="black"
                    />
                  </svg>
                </div>
              </div>
              <div className="relative inline-block text-left">
                <CustomDropdowns />
                {/*   <select className="block appearance-none w-full bg-white border border-gray-300 font-extrabold hover:border-gray-500 px-4 py-3 pr-8 rounded-full shadow leading-tight focus:outline-none focus:shadow-outline">
                <option>All Meters</option>
                <option>Meter 1</option>
                <option>Meter 2</option>
              </select> */}
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.415 0.292496L6 4.8775L10.585 0.292496L12 1.7075L6 7.7075L0 1.7075L1.415 0.292496Z"
                      fill="black"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
{/* <div className=" flex flex-col items-center">
      <div className="w-full  p-2">
        <div className="flex justify-between items-center">
          <h2 className="text-3xl font-bold text-gray-900">Reports</h2>
          <div className="flex space-x-4">
            <div className="relative inline-block text-left">
              <select className="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded-full shadow leading-tight focus:outline-none focus:shadow-outline">
                <option>Plant 1</option>
                <option>Plant 2</option>
                <option>Plant 3</option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.415 0.292496L6 4.8775L10.585 0.292496L12 1.7075L6 7.7075L0 1.7075L1.415 0.292496Z" fill="black"/>
</svg>
 </div>
            </div>
            <div className="relative inline-block text-left">
              <select className="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded-full shadow leading-tight focus:outline-none focus:shadow-outline">
                <option>All Meters</option>
                <option>Meter 1</option>
                <option>Meter 2</option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.415 0.292496L6 4.8775L10.585 0.292496L12 1.7075L6 7.7075L0 1.7075L1.415 0.292496Z" fill="black"/>
</svg>
    </div>
            </div>
          </div>
        </div>
      </div>
    </div> */}

    <div className="border border-gray-300 rounded-lg ml-4 inline-flex bg-white shadow-sm h-6 lg:h-16  px-2 max-w-fit">
      <div className="flex items-center h-full">
    {/* Filter Icon */}
    <div className="h-full px-1 lg:px-2 border-r border-gray-200 flex items-center justify-center">

    <svg width="22" height="24" className="h-2 w-2 lg:h-4 lg:w-4 text-gray-500" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M11 9.75C16.3848 9.75 20.75 7.73528 20.75 5.25C20.75 2.76472 16.3848 0.75 11 0.75C5.61522 0.75 1.25 2.76472 1.25 5.25C1.25 7.73528 5.61522 9.75 11 9.75Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M1.25 5.25C1.25253 9.76548 4.35614 13.688 8.75 14.729V21C8.75 22.2426 9.75736 23.25 11 23.25C12.2426 23.25 13.25 22.2426 13.25 21V14.729C17.6439 13.688 20.7475 9.76548 20.75 5.25" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

      
    </div>

    <div className="h-full px-1 lg:px-2 border-r border-gray-200 flex items-center justify-center">
      <span className=" text-xsxs sm:text-sm  font-bold text-gray-600">Filter By</span>
    </div>

    <div className="h-full relative px-1 lg:px-2 border-r border-gray-200 flex items-center justify-center">
      <select className="appearance-none  text-xsxs sm:text-sm  w-full font-bold  bg-white text-gray-600 py-0 lg:py-2 px-1 lg:px-4  pr-2 lg:pr-8 rounded leading-tight focus:outline-none">
        <option>Daily</option>
        <option>Weekly</option>
        <option>Monthly</option>
      </select>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pl-2 text-gray-600">
        <svg className="fill-current h-2 w-2 lg:h-4 lg:w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
        </svg>
      </div>
    </div>

    <div className="h-full relative px-1 lg:px-2 border-r border-gray-200 flex items-center justify-center">
      <select className="appearance-none  text-xsxs sm:text-sm  w-full font-bold bg-white text-gray-600 py-0 lg:py-2 px-1 lg:px-4  pr-2 lg:pr-8 rounded leading-tight focus:outline-none">
        <option>Last 30 Days</option>
        <option>Last 7 Days</option>
        <option>Last Year</option>
      </select>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pl-2 text-gray-600">
        <svg className="fill-current h-2 w-2 lg:h-4 lg:w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
        </svg>
      </div>
    </div>

    <div className="h-full relative px-1 lg:px-2 border-r border-gray-200 flex items-center justify-center">
      <select className="appearance-none   text-xsxs sm:text-sm  w-full font-bold bg-white text-gray-600 py-0 lg:py-2 px-1 lg:px-4 pr-2 lg:pr-8 rounded leading-tight focus:outline-none">
        <option>NOC Status</option>
        <option>Active</option>
        <option>Inactive</option>
        <option>Pending</option>
      </select>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pl-2 text-gray-600">
        <svg className="fill-current h-2 w-2 lg:h-4 lg:w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
        </svg>
      </div>
    </div>
    <div className="h-full relative px-1 lg:px-2 flex items-center justify-center">
    <span className="text-red-500 text-xsxs sm:text-sm font-bold cursor-pointer flex items-center">
  
      <svg width="12" height="16"   className="h-2 w-2 lg:h-4 lg:w-4 mr-1" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6 3.74994V0.749939L2.25 4.49994L6 8.24994V5.24994C8.4825 5.24994 10.5 7.26744 10.5 9.74994C10.5 12.2324 8.4825 14.2499 6 14.2499C3.5175 14.2499 1.5 12.2324 1.5 9.74994H0C0 13.0649 2.685 15.7499 6 15.7499C9.315 15.7499 12 13.0649 12 9.74994C12 6.43494 9.315 3.74994 6 3.74994Z" fill="#C83C36"/>
</svg>

      Reset Filter
    </span>
    </div>
  </div>
</div>





   {/* AG Grid   */}

   <div className="p-4  pt-0 min-h-screen flex flex-col items-center">
  <div className="w-full  rounded-2xl shadow-2xl overflow-hidden">
    <div className="ag-theme-alpine rounded-2xl shadow-2xl" style={{ height: 400, width: '100%', borderRadius: '18px' }}>
      <AgGridReact 
        rowData={rowData} 
        columnDefs={columnDefs} 
        defaultColDef={{ flex: 1, minWidth: 150 }}
        pagination={true}
        paginationPageSize={8}
        gridOptions={{
                suppressCellSelection: true,
                suppressMovableColumns: true, // Disable column drag and drop
              }}
              suppressHorizontalScroll={false}
      />
    </div>
  </div>
</div>
    </div>
  );
};

export default ReportLayout;


