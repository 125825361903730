// reducers.js
import { combineReducers } from 'redux';

const initialState = {
  isAuthenticated: localStorage.getItem('isAuthenticated') === 'true',
  accessToken: localStorage.getItem('accessToken') || null,
  error: null
};

const authReducer = (state = initialState, action) => {
  switch(action.type) {
    case 'LOGIN_SUCCESS':
      localStorage.setItem('isAuthenticated', 'true');
      localStorage.setItem('accessToken', action.payload);
      return {
        ...state,
        isAuthenticated: true,
        accessToken: action.payload,
        error: null
      };
    case 'LOGIN_FAILURE':
      localStorage.removeItem('isAuthenticated');
      localStorage.removeItem('accessToken');
      return {
        ...state,
        isAuthenticated: false,
        accessToken: null,
        error: action.payload
      };
    case 'LOGOUT':
      localStorage.removeItem('isAuthenticated');
      localStorage.removeItem('accessToken');
      return {
        ...state,
        isAuthenticated: false,
        accessToken: null,
        error: null
      };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  auth: authReducer
});

export default rootReducer;
