import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const GaugeChart = ({ value, min = 0, max = 100 }) => {
  const svgRef = useRef();

  useEffect(() => {
    const width = 150;
    const height = 150;
    const thickness = 20;
    const radius = Math.min(width, height) / 2 - thickness;
    const startAngle = -Math.PI / 2;  // Start at -90 degrees (top of the circle)
    const endAngle = Math.PI / 2;    // End at 90 degrees (bottom of the half-circle)
    const numSections = 3;

    const sectionColors = ['#32CD32', '#FFA500', '#FF4500']; // Green, Orange, Red
    const initialValue = 0;
    const intermediateValue = 100;
    const finalValue = value;

    // Clear any previous chart
    d3.select(svgRef.current).selectAll('*').remove();

    const svg = d3
      .select(svgRef.current)
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${width / 2}, ${height / 2})`);

    // Define the background sections (dividing the arc into 3 colored parts)
    const sectionAngles = [
      { startAngle, endAngle: startAngle + (endAngle - startAngle) / numSections, color: sectionColors[0] },
      { startAngle: startAngle + (endAngle - startAngle) / numSections, endAngle: startAngle + 2 * (endAngle - startAngle) / numSections, color: sectionColors[1] },
      { startAngle: startAngle + 2 * (endAngle - startAngle) / numSections, endAngle, color: sectionColors[2] }
    ];

    const arc = d3.arc()
      .innerRadius(radius - thickness)
      .outerRadius(radius);

    // Draw each section (background arcs)
    sectionAngles.forEach(section => {
      svg.append('path')
        .datum(section)
        .attr('d', arc)
        .attr('fill', section.color);
    });

    // Needle calculation
    const needleLength = radius - thickness - 5;
    const needleRadius = 5;

    // Mapping min and max values to the half-circle angle range (-π/2 to π/2)
    const angleScale = d3.scaleLinear()
      .domain([min, max])  // Min and Max values mapped to half arc
      .range([startAngle, endAngle]);

    const drawNeedle = (currentValue) => {
      const angle = angleScale(currentValue);

      svg.select('.needle').remove();  // Remove existing needle

      // Draw the needle
      svg.append('line')
        .attr('class', 'needle')
        .attr('x1', 0)
        .attr('y1', 0)
        .attr('x2', needleLength * Math.cos(angle))
        .attr('y2', needleLength * Math.sin(angle))
        .attr('stroke', '#007ACC')
        .attr('stroke-width', 3);

      // Needle base circle
      svg.select('.needle-base').remove();  // Remove existing base circle
      svg.append('circle')
        .attr('class', 'needle-base')
        .attr('cx', 0)
        .attr('cy', 0)
        .attr('r', needleRadius)
        .attr('fill', '#007ACC');
    };

    // Initial animation: move from 0 to 100 and then back to final value
    const animateNeedle = () => {
      let currentValue = initialValue;

      // Move from 0 to 100
      const transitionToMax = svg.transition()
        .duration(2000)
        .ease(d3.easeLinear)
        .tween('progress', () => {
          const interpolate = d3.interpolate(currentValue, intermediateValue);
          return (t) => {
            currentValue = interpolate(t);
            drawNeedle(currentValue);
          };
        });

      // Move back to the final value
      transitionToMax.transition()
        .duration(1000)
        .ease(d3.easeBounceOut)
        .tween('progress', () => {
          const interpolate = d3.interpolate(intermediateValue, finalValue);
          return (t) => {
            currentValue = interpolate(t);
            drawNeedle(currentValue);
          };
        });
    };

    // Start the animation
    animateNeedle();

    // Add the value text in the middle
    svg.append('text')
      .attr('text-anchor', 'middle')
      .attr('dy', '0.35em')
      .attr('font-size', '24px')
      .text(`${finalValue}%`);

  }, [value, min, max]);

  return <svg className="gaugepos" ref={svgRef}></svg>;
};

export default GaugeChart;
