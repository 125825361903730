// authService.js

//import { CognitoUser, AuthenticationDetails,CognitoUserPool } from 'amazon-cognito-identity-js';
import { loginSuccess, loginFailure,logout } from '../action/Auth-action';
import toast from 'react-hot-toast';
import axios from 'axios';

const authService = {
  login: (username, password, dispatch) => {
    const authenticationData = {
      Username: username,
      Password: password
    };

 /*    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const userData = {
        Username: username,
        Pool: new CognitoUserPool({
          UserPoolId: 'ap-south-1_WAfDFKX7J',
          ClientId: '3gcu8hnbhvksf1u4ebrgdso4v0',
        }),
      };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.authenticateUser(authenticationDetails, {

      onSuccess: (result) => {
        const accessToken = result.getAccessToken().getJwtToken();
        dispatch(loginSuccess(accessToken));
        localStorage.setItem('accessToken', accessToken);
        toast.success('Auth Success')
        
      },
      onFailure: (error) => {
        dispatch(loginFailure(error.message));
        toast.error(error.message)
      }

    }); */
    const accessToken = "sdfsdfsdfsdfsdfsdfsdfs";
    dispatch(loginSuccess(accessToken));
    localStorage.setItem('accessToken', accessToken);
    toast.success('Auth Success')
    
  },

  logout: (dispatch) => {
    localStorage.clear();
    dispatch(logout());
    window.location.href = '/login';
  },
  setAuthHeader: () => {
    const accessToken = localStorage.getItem('accessToken');
    console.log(accessToken);
    if (accessToken) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    } else {
      delete axios.defaults.headers.common['Authorization'];
    }
  }
};

export default authService;
