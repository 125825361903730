import React from 'react';

const meters = [
  { id: 'FM0989345',  status: 'Active',value: '68.2 KL', time: 'Thursday, 11 Nov 2021 8:00 AM to Friday, 12 Nov 2021 8:00 AM' },
  { id: 'FM09889321',status: 'Active', value: '68.2 KL', time: 'Thursday, 11 Nov 2021 8:00 AM to Friday, 12 Nov 2021 8:00 AM' },
  { id: 'FM09889186',status: 'Active', value: '78.2 KL', time: 'Thursday, 11 Nov 2021 8:00 AM to Friday, 12 Nov 2021 8:00 AM' },
  { id: 'FM09889310',status: 'InActive', value: '68.2 KL', time: 'Thursday, 11 Nov 2021 8:00 AM to Friday, 12 Nov 2021 8:00 AM' },
  
  { id: 'FM0989345', status: 'Active',value: '68.2 KL', time: 'Thursday, 11 Nov 2021 8:00 AM to Friday, 12 Nov 2021 8:00 AM' },
  { id: 'FM09889321',status: 'Active', value: '68.2 KL', time: 'Thursday, 11 Nov 2021 8:00 AM to Friday, 12 Nov 2021 8:00 AM' },
  { id: 'FM09889186', status: 'Active',value: '78.2 KL', time: 'Thursday, 11 Nov 2021 8:00 AM to Friday, 12 Nov 2021 8:00 AM' },
  { id: 'FM09889310', status: 'Active',value: '68.2 KL', time: 'Thursday, 11 Nov 2021 8:00 AM to Friday, 12 Nov 2021 8:00 AM' },
];

const MeterList = () => (
  <div className="bg-white p-4 rounded-lg text-left h-full overflow-y-auto">
    <div className="mb-4">
      <h2 className="text-lg font-semibold">Meters <span className="font-normal">5</span></h2>
    </div>
    {meters.map((meter) => (
      <div key={meter.id} className={`p-4 mb-4 text-left border-l-4 rounded-lg shadow-sm ${
          meter.status === 'Active' ? 'custom-border' : 'custom-borderred'} bg-white`}>
        <div className="text-sm font-bold text-black">{meter.id}</div>
        <div className="text-4xl font-black text-primary">{meter.value}</div>
        <div className="text-xs text-gray-500 col-span-2 md:col-span-1">{meter.time}</div>
      </div>
    ))}
  </div>
);

export default MeterList;