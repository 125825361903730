import React from 'react';
import { Line } from 'react-chartjs-2';
import { FiChevronDown } from 'react-icons/fi';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Chart as ChartJS, LineElement, PointElement, LinearScale, Title, Tooltip, Filler, Legend, CategoryScale } from 'chart.js';

ChartJS.register(
  LineElement, PointElement, LinearScale, Title, Tooltip, Filler, Legend, CategoryScale, annotationPlugin
);

const ConsumptionTrend = () => {
  const data = {
    labels: ['27 Jun', '28 Jun', '29 Jun', '30 Jun', '1 Jul', '2 Jul', '3 Jul', '4 Jul', '5 Jul', '6 Jul', '7 Jul'],
    datasets: [
      {
        label: 'Consumption',
        data: [20, 50, 80, 330, 50, 180, 30, 200, 300, 150, 170],
        fill: true,
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 400);
          gradient.addColorStop(0, '#2B3C7342');
          gradient.addColorStop(1, '#FFFFFF2D');
          return gradient;
        },
        borderColor: '#2B3C73',
        tension: 0.4,
        
        pointBackgroundColor: '#2B3C73',
        pointBorderColor: '#2B3C73',
        pointHoverRadius: 6,
        pointHoverBackgroundColor: '#2B3C73',
        pointHoverBorderColor:  '#2B3C73',
        pointRadius: 4,
        pointHoverBorderWidth: 2,
        pointHitRadius: 10,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: 'rgba(200, 200, 200, 0.2)',
        },
       
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
        font: {
          weight: 'bold', // Bold font for x-axis labels
        },
        callback: function (value) {
          return this.getLabelForValue(value); // Use default label callback
        },
        maxRotation: 90, // Rotate labels up to 90 degrees
        minRotation: 45, // Minimum rotation of 45 degrees
      },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      annotation: {
        annotations: {
          redLine: {
            type: 'line',
            yMin: 300,
            yMax: 300,
            borderColor: '#C83C36',
            borderWidth: 2,
            borderDash: [6, 2],
            label: {
              content: 'Red Line',
              enabled: true,
              position: 'end',
              backgroundColor: '#C83C36',
            },
          },
          yellowLine: {
            type: 'line',
            yMin: 200,
            yMax: 200,
            borderColor: '#EA9E00',
            borderWidth: 2,
            borderDash: [6, 2],
            label: {
              content: 'Yellow Line',
              enabled: true,
              position: 'end',
              backgroundColor: '#EA9E00',
            },
          },
        },
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: (tooltipItem) => `${tooltipItem.raw} kL`,
        },
        backgroundColor: '#C83C36',
        titleFont: {
          weight: 'bold', // Bold font for tooltip title
        },
        bodyFont: {
          weight: 'bold', // Bold font for tooltip body
        },
        titleColor: '#FFFFFF', // White color for tooltip title text
        bodyColor: '#FFFFFF',
      },
    },
  };

  return (
    <div className="bg-white p-4 rounded-lg shadow-lg">
      <div className="flex justify-between items-center mb-2">
        <h2 className="text-lg font-semibold">Consumption Trend (kL)</h2>
       
        <select className="flex items-center text-gray-500 border border-gray-300 rounded-full px-4 py-1">
                <option>Last 12 months</option>
                <option>Last 6 months</option>
                <option>Last 3 months</option>
              </select>
      </div>
      <div className="h-64 md:h-80">
        <Line data={data} options={options} />
      </div>
    </div>
  );
};

export default ConsumptionTrend;
