
import React, { useState } from 'react';
import { FiList, FiGrid } from 'react-icons/fi';
import meterImg from '../../assets/meter.png';
import pattern from '../../assets/Pattern.png';
import activeBadge from '../../assets/green.svg';
import inactiveBadge from '../../assets/red.svg';
import { AgGridReact } from 'ag-grid-react';

import HelpQueryModal from '../models/HelpQueryModal'; 


const Pagination = ({ currentPage, setPage, totalPages }) => {
  return (
    <div className="flex justify-center mt-4">

    <button

      onClick={() => setPage(currentPage > 1 ? currentPage - 1 : currentPage)}

      className="border-r border-gray-300 px-4 py-2 bg-white text-gray-600 rounded-l-lg"

    >

      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-5 h-5">

        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />

      </svg>

    </button>

    <button

      onClick={() => setPage(currentPage < totalPages ? currentPage + 1 : currentPage)}

      className="border-l border-gray-300 px-4 py-2 bg-white text-gray-600 rounded-r-lg"

    >

      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-5 h-5">

        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
      </svg>

    </button>

  </div>
  );
};
const MetersLayout = () => {
    const meters = [
        { id: 1, name: 'FM098897801', location: 'Basement Borewell 1', status: 'Active', imgSrc: meterImg },
        { id: 2, name: 'FM098897802', location: 'Basement Borewell 1', status: 'Inactive', imgSrc: meterImg },
        { id: 3, name: 'FM098897803', location: 'Basement Borewell 1', status: 'Active', imgSrc: meterImg },
        { id: 4, name: 'FM098897804', location: 'Basement Borewell 1', status: 'Active', imgSrc: meterImg },
        { id: 5, name: 'FM098897805', location: 'Basement Borewell 1', status: 'Active', imgSrc: meterImg },
        { id: 6, name: 'FM098897806', location: 'Basement Borewell 1', status: 'Active', imgSrc: meterImg },
        { id: 7, name: 'FM098897807', location: 'Basement Borewell 1', status: 'Active', imgSrc: meterImg },
        { id: 8, name: 'FM098897808', location: 'Basement Borewell 1', status: 'Active', imgSrc: meterImg },
        { id: 9, name: 'FM098897809', location: 'Basement Borewell 1', status: 'Active', imgSrc: meterImg },
        { id: 10, name: 'FM098897800', location: 'Basement Borewell 1', status: 'Inactive', imgSrc: meterImg },
        { id: 11, name: 'FM0988978011', location: 'Basement Borewell 1', status: 'Active', imgSrc: meterImg },
        { id:12, name: 'FM0988978012', location: 'Basement Borewell 1', status: 'Active', imgSrc: meterImg },
        { id: 13, name: 'FM0988978013', location: 'Basement Borewell 1', status: 'Active', imgSrc: meterImg },
        { id: 14, name: 'FM0988978014', location: 'Basement Borewell 1', status: 'Active', imgSrc: meterImg },
        { id: 15, name: 'FM0988978015', location: 'Basement Borewell 1', status: 'Active', imgSrc: meterImg },
        { id: 16, name: 'FM0988978016', location: 'Basement Borewell 1', status: 'Active', imgSrc: meterImg },
      ];
      
      const [view, setView] = useState('grid');
      const [currentPage, setCurrentPage] = useState(1);
      const itemsPerPage = 4;
      
      const indexOfLastItem = currentPage * itemsPerPage;
      const indexOfFirstItem = indexOfLastItem - itemsPerPage;
      const currentMeters = meters.slice(indexOfFirstItem, indexOfLastItem);
    const Card = ({ meter }) => (
      <div
      className="relative flex flex-col justify-between bg-white rounded-2xl shadow p-2 lg:p-6"
      style={{
        backgroundImage: `url(${pattern})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      {meter.status === 'Active' && (
        <div className="absolute top-2 right-2 text-xs font-medium inline-flex items-center px-2 py-1 activetag">
          <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7 3.5C7 5.433 5.433 7 3.5 7C1.567 7 0 5.433 0 3.5C0 1.567 1.567 0 3.5 0C5.433 0 7 1.567 7 3.5Z"
              fill="#C9E0CA"
            />
          </svg>
          &nbsp; Active
        </div>
      )}
      {meter.status !== 'Active' && (
        <div className="absolute top-2 right-2 text-xs font-medium inline-flex items-center px-2 py-1 inactivetag">
          <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M10 6.5C10 8.433 8.433 10 6.5 10C4.567 10 3 8.433 3 6.5C3 4.567 4.567 3 6.5 3C8.433 3 10 4.567 10 6.5Z"
              fill="#E4A4A1"
            />
          </svg>
          &nbsp; Inactive
        </div>
      )}
      <div className="flex flex-col justify-center items-center" style={{ paddingTop: '2rem' }}>
        <img src={meter.imgSrc} alt="Meter" className="w-24 h-24 rounded-lg mb-2" />
        <h5 className="text-sm sm:text-sm md:text-md lg:text-md font-bold headingcolor">{meter.name}</h5>
        <p className="text-xxs sm:text-sm md:text-md lg:text-md text-gray-500 inline-flex items-center">
        {meter.location}
          <span role="img" aria-label="edit" className="ml-2">
            <svg width="10" height="10" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11.7187 2.96254L9.03791 0.281244C8.94876 0.19208 8.84292 0.12135 8.72643 0.0730938C8.60995 0.0248374 8.48509 0 8.359 0C8.23291 0 8.10806 0.0248374 7.99157 0.0730938C7.87508 0.12135 7.76924 0.19208 7.68009 0.281244L0.281408 7.68016C0.191875 7.76898 0.12089 7.87471 0.0725812 7.9912C0.024272 8.10769 -0.000398068 8.23262 4.85703e-06 8.35874V11.04C4.85703e-06 11.2946 0.101149 11.5388 0.281186 11.7188C0.461223 11.8989 0.705405 12 0.960016 12H3.64145C3.76756 12.0004 3.8925 11.9757 4.009 11.9274C4.1255 11.8791 4.23123 11.8081 4.32006 11.7186L11.7187 4.32029C11.8079 4.23115 11.8786 4.12531 11.9269 4.00883C11.9752 3.89235 12 3.7675 12 3.64142C12 3.51533 11.9752 3.39049 11.9269 3.274C11.8786 3.15752 11.8079 3.05169 11.7187 2.96254ZM3.64145 11.04H0.960016V8.35874L6.24008 3.07894L8.92151 5.76023L3.64145 11.04ZM9.60012 5.08106L6.91869 2.40036L8.3587 0.960418L11.0401 3.64112L9.60012 5.08106Z"
                fill="#0AB8D9"
              />
            </svg>
          </span>
        </p>
      </div>
      <div className="mt-2 flex justify-between space-x-1 lg:space-x-2 w-full">
      <button className="bg-white border font-bold border-gray-500 text-gray-700 px-4 py-1 rounded-full w-full 
  sm:px-6 sm:py-1 sm:text-xs 
  lg:px-4 lg:py-1 lg:text-lg">
  Info
</button>

<a href="/meter-details" 
  className={`font-bold rounded-full w-full px-4 py-1 text-xs 
  sm:px-6 sm:py-1 sm:text-xs 
  lg:px-4 lg:py-1 lg:text-lg ${
    meter.status === 'Active' ? 'bg-yellow text-black' : 'bg-gray-300 text-gray-500'
  }`}
>
  Analytics
</a>
      </div>
    </div>
      );
      const Cards = ({ meter }) => (


        <div className="overflow-x-auto ">
      <table className="min-w-full rounded-lg shadow-md">
       
        <tbody>
        
            <tr>
               <td className="px-6 py-4 whitespace-no-wrap  text-sm leading-5 text-gray-900 font-bold">{meter.id}</td>
               <td className="px-6 py-4 whitespace-no-wrap  text-sm leading-5 text-gray-900 font-bold">{meter.name}</td>
              <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5">
                <span className={`px-2 inline-flex text-xs leading-5 font-normal rounded-full ${meter.status === 'Active' ? 'statusgreen' : 'statusred'}`}>
                <svg width="7" height="7" viewBox="0 0 7 7" fill="none" style={{    margin: '5px'}} xmlns="http://www.w3.org/2000/svg">
<path d="M7 3.5C7 5.433 5.433 7 3.5 7C1.567 7 0 5.433 0 3.5C0 1.567 1.567 0 3.5 0C5.433 0 7 1.567 7 3.5Z" fill="#C9E0CA"/>
</svg>
 {meter.status}
                </span>
              </td>
              <td className="px-6 py-4 whitespace-no-wrap  text-sm leading-5 text-gray-900">
              <p className="text-gray-500 inline-flex items-center">
      {meter.location}
      <span role="img" aria-label="edit" className="ml-2">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.7187 2.96254L9.03791 0.281244C8.94876 0.19208 8.84292 0.12135 8.72643 0.0730938C8.60995 0.0248374 8.48509 0 8.359 0C8.23291 0 8.10806 0.0248374 7.99157 0.0730938C7.87508 0.12135 7.76924 0.19208 7.68009 0.281244L0.281408 7.68016C0.191875 7.76898 0.12089 7.87471 0.0725812 7.9912C0.024272 8.10769 -0.000398068 8.23262 4.85703e-06 8.35874V11.04C4.85703e-06 11.2946 0.101149 11.5388 0.281186 11.7188C0.461223 11.8989 0.705405 12 0.960016 12H3.64145C3.76756 12.0004 3.8925 11.9757 4.009 11.9274C4.1255 11.8791 4.23123 11.8081 4.32006 11.7186L11.7187 4.32029C11.8079 4.23115 11.8786 4.12531 11.9269 4.00883C11.9752 3.89235 12 3.7675 12 3.64142C12 3.51533 11.9752 3.39049 11.9269 3.274C11.8786 3.15752 11.8079 3.05169 11.7187 2.96254ZM3.64145 11.04H0.960016V8.35874L6.24008 3.07894L8.92151 5.76023L3.64145 11.04ZM9.60012 5.08106L6.91869 2.40036L8.3587 0.960418L11.0401 3.64112L9.60012 5.08106Z" fill="#0AB8D9"/>
        </svg>
      </span>
    </p>
              </td>
              <td className="px-6 py-4 whitespace-no-wrap  text-sm leading-5 text-gray-900">
                <img src={meter.imgSrc} alt="Meter" className="w-10 h-10 rounded-lg" />
              </td>
              <td className="px-6 py-4 whitespace-no-wrap  text-sm leading-5 text-gray-900">
                <button className="bg-white border font-bold border-gray-500 mx-2  text-gray-700 px-7 py-2 rounded-full">Info</button>
                <a href="/meter-details"  className={`px-4 py-2 rounded-full font-bold flex-1 mx-2 ${
      meter.status === "Active"
        ? "bg-yellow text-black"
        : "bg-gray-300 text-gray-500"
    }`}
  >Analytics</a>
              </td>
            </tr>
          
        </tbody>
      </table>
    </div>
    
      );









      const MobileCard = ({ meter }) => {
        return ( <>
          <div className="bg-white rounded-t-2xl shadow p-4 pb-0 mb-0 w-full">

          <div className="flex justify-between items-start mb-2">
            <div className="flex items-center space-x-4">
              <img src={meter.imgSrc} alt="Meter" className="w-12 h-12 rounded-lg" />
              <div>
                <h3 className="text-sm font-bold text-gray-900">{meter.name}</h3>
                <p className="text-xxs text-gray-500 inline-flex items-center">
                  {meter.location}&nbsp;
                  <span role="img" aria-label="edit" className="ml-2">
            <svg width="10" height="10" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11.7187 2.96254L9.03791 0.281244C8.94876 0.19208 8.84292 0.12135 8.72643 0.0730938C8.60995 0.0248374 8.48509 0 8.359 0C8.23291 0 8.10806 0.0248374 7.99157 0.0730938C7.87508 0.12135 7.76924 0.19208 7.68009 0.281244L0.281408 7.68016C0.191875 7.76898 0.12089 7.87471 0.0725812 7.9912C0.024272 8.10769 -0.000398068 8.23262 4.85703e-06 8.35874V11.04C4.85703e-06 11.2946 0.101149 11.5388 0.281186 11.7188C0.461223 11.8989 0.705405 12 0.960016 12H3.64145C3.76756 12.0004 3.8925 11.9757 4.009 11.9274C4.1255 11.8791 4.23123 11.8081 4.32006 11.7186L11.7187 4.32029C11.8079 4.23115 11.8786 4.12531 11.9269 4.00883C11.9752 3.89235 12 3.7675 12 3.64142C12 3.51533 11.9752 3.39049 11.9269 3.274C11.8786 3.15752 11.8079 3.05169 11.7187 2.96254ZM3.64145 11.04H0.960016V8.35874L6.24008 3.07894L8.92151 5.76023L3.64145 11.04ZM9.60012 5.08106L6.91869 2.40036L8.3587 0.960418L11.0401 3.64112L9.60012 5.08106Z"
                fill="#0AB8D9"
              />
            </svg>
          </span>
                </p>
              </div>
            </div>
            <div className={`px-3 py-1 text-xs font-semibold rounded-full ${meter.status === 'Active' ? 'bg-g text-white' : 'bg-r text-white'}`}>
              <svg className="w-2 h-2 inline-block mr-1" fill="currentColor" viewBox="0 0 8 8">
                <circle cx="4" cy="4" r="4" />
              </svg>
              {meter.status}
            </div>
          </div> </div>
          
          <div className="bg-white rounded-b-2xl shadow mb-4 w-full" style={{'marginTop': '-8px'}} >
  <div className="flex">
    <button className="flex-1 bg-white  font-bold  text-gray-700 py-1 rounded-b-2xl">
      Info
    </button>
    <a href="/meter-details" 
       className={`flex-1 py-1 rounded-b-2xl font-bold ${
         meter.status === "Active" ? "bg-yellow-400 text-black" : "bg-gray-300 text-gray-500"
       }`}
     style={{'border-bottom-left-radius':'0','textAlign':'center'}}>
      Analytics
    </a>
  </div>
</div>
      </>
        );
      }
      const CustomDropdown = () => {
        const [isOpen, setIsOpen] = useState(false);
        const [selectedOption, setSelectedOption] = useState('Plant 1');
      
        const toggleDropdown = () => {
          setIsOpen(!isOpen);
        };
      
        const selectOption = (option) => {
          setSelectedOption(option);
          setIsOpen(false);
        };
      
        return (
          <div className="relative inline-block w-full">
            {/* Custom Dropdown Button */}
            <div
              className="block w-full bg-white border border-gray-300 font-extrabold  lg:text-lg  hover:border-gray-500 lg:px-4 lg:py-3 lg:pr-8 meterbut sm:meterbut rounded-full shadow leading-tight cursor-pointer"
              onClick={toggleDropdown}
            >
              {selectedOption}
            </div>
      
            {/* Dropdown List */}
            {isOpen && (
              <ul className="absolute z-10 w-full bg-white border border-gray-300 rounded-md shadow-lg max-h-48 overflow-auto">
                <li
                  className="lg:px-4 lg:py-2 px-4 py-2 sm:text-[8px] lg:text-lg hover:bg-bluetextcolor cursor-pointer"
                  onClick={() => selectOption('Plant 1')}
                >
                  Plant 1
                </li>
                <li
                  className="lg:px-4 lg:py-2 px-4 py-2  lg:text-lg sm:text-[8px]  hover:bg-bluetextcolor cursor-pointer"
                  onClick={() => selectOption('Plant 2')}
                >
                  Plant 2
                </li>
                <li
                  className="lg:px-4 lg:py-2 px-4 py-2  lg:text-lg sm:text-[8px] hover:bg-bluetextcolor cursor-pointer"
                  onClick={() => selectOption('Plant 3')}
                >
                  Plant 3
                </li>
              </ul>
            )}
          </div>
        );
      };
    
  return (
    
    <div className="p-4 flex flex-col space-y-4 bg-greycolor">
    <div className="min-h-screen">
        <div className="w-full mx-auto">
            <div className="flex justify-between items-center mb-4 meterpadding">
                <div className="flex items-center space-x-2 md:space-x-4 ">
                    <h2 className="text-sm md:text-2xl font-bold text-gray-900">Meters | </h2>
                    <div className="relative inline-block text-left">
                        <CustomDropdown />
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.415 0.292496L6 4.8775L10.585 0.292496L12 1.7075L6 7.7075L0 1.7075L1.415 0.292496Z" fill="black" />
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="flex space-x-2">
                    <button className="bg-bluecolor text-white lg:px-6 lg:py-2  sm:buybutton  buybutton rounded-full">+ Buy New Device</button>
                    <div className="flex space-x-0 border border-gray-300 rounded-md overflow-hidden">
                        <button
                            onClick={() => setView('grid')}
                            className={`flex-1 p-3 sm:p-1 border-r border-gray-300 ${view === 'grid' ? 'bg-white' : 'bg-gray-100'} focus:outline-none`}
                        >
                            <FiGrid className={`w-2 h-2 md:w-6 md:h-6 sm:w-5 sm:h-5 mx-auto ${view === 'grid' ? 'text-black' : 'text-gray-400'}`} />
                        </button>
                        <button
                            onClick={() => setView('list')}
                            className={`flex-1 p-3 sm:p-2 ${view === 'list' ? 'bg-white' : 'bg-gray-100'} focus:outline-none`}
                        >
                            <FiList className={`w-2 h-2 md:w-6 md:h-6 sm:w-5 sm:h-5 mx-auto ${view === 'list' ? 'text-black' : 'text-gray-400'}`} />
                        </button>
                    </div>
                    </div>
                    </div>
                    <div className={`grid ${view === 'grid' ? 'grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4' : 'grid-cols-1'} gap-4 lg:ml-4 lg:mr-4 sm:ml-2 sm:mr-2`}>
                        {view === 'grid' ? (
                            currentMeters.map(meter => (
                                <Card key={meter.id} meter={meter} />
                            ))
                        ) : (
                                
                                  <>
                                                                                                    
                                                                                                          
                                                            <div className="w-full block lg:hidden sm:block">
                                                            {currentMeters.map(meter => (
                                                              <div key={meter.id} className=" mt-2">
                                                            <MobileCard meter={meter} />
                                                            </div>
                                                          ))}
                                                          </div>
                                                            
                                                            {/* Display only on Mobile */}
                                                            <div className="hidden lg:block sm:hidden">
                                                            <div className='bg-white rounded-md shadow-sm p-4'>
                                                            {currentMeters.map(meter => (
                                                            <div key={meter.id} className="bg-greycolor bordergrey mt-2">
                                                            
                                                          
                                                            <Cards meter={meter} />
                                                           
                                                          </div>
                                                          ))}
                                                          </div>
                                                          </div>
                                      
</>
                                  
                                
                            )}
                    </div>
                    <Pagination 
                        currentPage={currentPage} 
                        setPage={setCurrentPage} 
                        totalPages={Math.ceil(meters.length / itemsPerPage)} 
                    />
                </div>
            </div>
        </div>
  );
};

export default MetersLayout;


