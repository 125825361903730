import React from "react";
import { NavLink, Outlet } from "react-router-dom";

const DisplayLayout = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div>
      <div className="" style={{marginLeft: '0rem'}}>
        <div className=" rounded-lg dark:border-gray-700">
          <Outlet />
        </div>
      </div>
       <div className="my-5 flex items-center justify-center text-center">
        <p className="font-medium text-sm text-gray-500">@ {currentYear} Kritsnam Technologies. All rights reserved</p>
      </div>
    </div>
  );
};

export default DisplayLayout;
