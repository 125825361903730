import React, { useContext, useEffect, useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import logoDesktop from '../../logo/logoD.png'
import logoMobile from '../../logo/logoM.png'
import notification from '../../icon/nav/notification.png'
import Bell from '../../icon/nav/CCare.png'
import Profile from '../../icon/nav/profile.png'
import authService from '../../redux/serviecs/Auth-serviecs';
import { useDispatch, useSelector } from 'react-redux';
//import Modal from "react-responsive-modal";
//import "../Pages/Home/Modalcss.css";
import dash from '../../icon/home/dash.png'
import { UnitContext } from "../../contexts/UnitContext";
import SubmitQueryModal from "../models/HelpQueryModal";
import ChangeUnitsModal from "../models/ChangeUnit";
import ChangePasswordModal from "../models/ChangePassword";
import LogoutModal from "../models/LogOutModel"; 
import { FaBars, FaTachometerAlt, FaChartPie, FaClipboard, FaBell, FaQuestionCircle, FaChevronDown } from 'react-icons/fa';
import logo from '../../assets/logo-2-1.svg';
import more from '../../assets/More.svg';
import './../../assets/faders.svg';
import './../../assets/line-65.svg';
import searchIcon from './../../assets/search.svg';

import faders from './../../assets/faders.svg';
import manIcon from './../../assets/man-438081-960-720.svg';
const Navbar = () => {

  const { selectedUnit, setSelectedUnit } = useContext(UnitContext);

  const handleUnitChange = (event) => {
    const unit = event.target.value;
    setSelectedUnit(unit);
  };
  const [open2, setOpen2] = useState(false);
  const onOpenModal2 = () => setOpen2(true);
  const onCloseModal2 = () => setOpen2(false);

  const [open3, setOpen3] = useState(false);
  const onOpenModal3 = () => setOpen3(true);
  const onCloseModal3 = () => setOpen3(false);
  
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);


  const [open4, setOpen4] = useState(false);
  const onOpenModal4 = () => setOpen4(true);
  const onCloseModal4 = () => setOpen4(false);


  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setIsOpen(true); // Open the sidebar on larger screens
      } else {
        setIsOpen(false); // Close the sidebar on smaller screens
      }
    };

    // Initial check
    handleResize();

    // Listen for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Function to close sidebar when a menu item is clicked (on mobile only)
  const handleMenuClick = () => {
    if (window.innerWidth < 768) {  // Only close on mobile
      setIsOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  const handleLogout = () => {
    authService.logout(dispatch);
  };

  const handleSubmit = () => {
    authService.logout(dispatch);
  };

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path;
  };

  return (
    <div>
      <nav class=" lg:top-0 lg:z-50 lg:w-full justify-between bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700 sm:flex sm:items-center">
        
        
        
        
      <div className="flex max-w-xxl items-center space-x-2 px-4  py-2 pl-8" >
                 
                       <div className="relative w-full max-w-lg">
                     
                          <input
                            type="text"
                            placeholder="Search by Plant and meter id"
                            style={{width:'400px'}}
                            className="w-full pl-12 pr-4 py-2 rounded-full bg-gray-100 border border-gray-300 placeholder-gray-500 focus:outline-none focus:border-gray-500"
                          />
                          <img src={searchIcon} alt="Search Icon" className="absolute left-3 top-1/2 transform -translate-y-1/2 w-4 h-4 opacity-50" />
                          <img src={faders} alt="Faders Icon" className="absolute right-3 top-1/2 transform -translate-y-1/2 w-4 h-4 opacity-50" />
                        </div> 
                  </div>
        
      <div class="flex items-center gap-4">
            <div className="hidden md:flex items-center space-x-2  py-2 px-4  ">
                  <div className="flex justify-center items-center w-10 h-10 rounded-lg bg-gray-100 border border-gray-300">
                    <span className="font-bold text-gray-700">19</span>
                  </div>
                  <div>
                    <span className="block font-bold text-sm text-gray-900">Monday</span>
                    <span className="block text-xs text-gray-500">Aug, 2024</span>

                  
                  </div>
                </div>
                <div><span><svg width="1" height="28" viewBox="0 0 1 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <line x1="0.5" y1="0.507813" x2="0.499999" y2="27.4922" stroke="black"/>
                  </svg>
                  </span></div>
                        <div>
                        
                        <span><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="12" cy="12" r="12" fill="#2B3C73"/>
                  <path d="M13.2 16.8C13.2 17.0373 13.1296 17.2693 12.9978 17.4667C12.8659 17.664 12.6785 17.8178 12.4592 17.9087C12.2399 17.9995 11.9987 18.0232 11.7659 17.9769C11.5331 17.9306 11.3193 17.8163 11.1515 17.6485C10.9836 17.4807 10.8694 17.2669 10.8231 17.0341C10.7768 16.8013 10.8005 16.5601 10.8913 16.3408C10.9822 16.1215 11.136 15.9341 11.3333 15.8022C11.5307 15.6704 11.7627 15.6 12 15.6C12.3183 15.6 12.6235 15.7264 12.8485 15.9515C13.0736 16.1765 13.2 16.4817 13.2 16.8ZM12 6C9.794 6 8 7.615 8 9.6V10C8 10.2122 8.08429 10.4157 8.23431 10.5657C8.38434 10.7157 8.58783 10.8 8.8 10.8C9.01217 10.8 9.21566 10.7157 9.36569 10.5657C9.51571 10.4157 9.6 10.2122 9.6 10V9.6C9.6 8.5 10.677 7.6 12 7.6C13.323 7.6 14.4 8.5 14.4 9.6C14.4 10.7 13.323 11.6 12 11.6C11.7878 11.6 11.5843 11.6843 11.4343 11.8343C11.2843 11.9843 11.2 12.1878 11.2 12.4V13.2C11.2 13.4122 11.2843 13.6157 11.4343 13.7657C11.5843 13.9157 11.7878 14 12 14C12.2122 14 12.4157 13.9157 12.5657 13.7657C12.7157 13.6157 12.8 13.4122 12.8 13.2V13.128C14.624 12.793 16 11.338 16 9.6C16 7.615 14.206 6 12 6Z" fill="white"/>
                  </svg>

                  </span>
                        </div>
                        <div className="flex items-center space-x-2  py-2 px-4   ">
                          <img src={manIcon} alt="User Avatar" className="w-8 h-8 rounded-full" />
                          <div className="hidden md:block">
                            <span className="block font-bold text-sm text-gray-900">Mani Roy</span>
                            <span className="block text-xs text-gray-500">Admin</span>
                          </div>
                        </div>
                                <div className="">
                            
                                </div>
            </div>
        
        
        
        
        
        
        
        
        
        
        
        
        
        
       
      </nav>

    </div>
  );
};

export default Navbar;
