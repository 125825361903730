import React, { useState } from 'react';
import drainageImg from '../../assets/drainage-1.svg';
import waterPumpImg from '../../assets/water-pump-1.svg';
import iconImg from '../../assets/icon.svg';
import cardImg from '../../assets/card.svg';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { LineChart, Line ,AreaChart, Area} from 'recharts';

const SupportLayout = () => {

    const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };
    
    



   
  
  return (
    <div className="p-4 flex flex-col space-y-4" style={{backgroundColor:'#F5F6FA'}}>
{/* meter dashboard   */}
<div className=" flex flex-col items-center supportpadding">
      <div className="w-full  p-2">
        <div className="flex justify-between items-center">
          <h2 className="text-3xl font-bold text-gray-900">Support</h2>
         {/*  <div className="flex space-x-4">
            <div className="relative inline-block text-left space-x-4">
            <button className="bg-yellow-400 text-black font-semibold px-4 py-2 rounded-full hover:bg-yellow-500">
           Download User Manual
          </button>
         
          <button className="border border-blue-500 primarycolor font-semibold px-4 py-2 rounded-full hover:bg-blue-100">
            Ask Your Query ?
          </button>
            </div>
          </div> */}

          <div className="flex space-x-4">
  <div className="relative inline-flex items-center text-left space-x-2">
    <button className="bg-yellow-400 text-black font-semibold px-1 py-0 lg:px-4 lg:py-2 rounded-full hover:bg-yellow-500">
    Download User Manual
    </button>
    <button className="border border-blue-500 primarycolor font-semibold px-1 py-0 lg:px-4 lg:py-2 rounded-full hover:bg-blue-100">
      Ask Your Query?
    </button>
  </div>
</div>
        </div>
      </div>
    </div>


    <div className="p-2 lg:p-4  bg-white rounded-lg shadow-md ml-2 lg:ml-4 mt-4 mr-2">
      <h2 className="text-2xl font-semibold mb-6">Frequently Asked Questions (FAQs)</h2>
      <div className="space-y-4">
        {[
          {
            question: "Setting NOC Limit",
            answer: (
              <>
                <p>Customer can select NOC limit from three dot menu of Homepage or from Meter Settings in Settings Menu.</p>
                <p className="font-semibold">Step 1:</p> Go to Three Homepage/ Dashboard Menu
                <p className="font-semibold">Step 2:</p> Click on Three Dot Menu - Select <em>View NOC limit</em>
                <p className="font-semibold">Step 3:</p> You will be able to see <strong>Current NOC limit</strong> and <strong>Update New Limit</strong>
                <p className="font-semibold">Step 4:</p> Set your <strong>New NOC limit</strong> and Click <strong>Update</strong>
              </>
            ),
          },
          {
            question: "Setting Internal Limit",
            answer: "Details on setting internal limit.",
          },
          {
            question: "Upgrade Calibration Request",
            answer: "Details on upgrading calibration request.",
          },
          {
            question: "Updating Personal Details",
            answer: "Details on updating personal details.",
          },
          {
            question: "Downloading Report",
            answer: "Details on downloading report.",
          },
        ].map((faq, index) => (
          <div key={index} className="border rounded-lg">
            <button
              onClick={() => toggleFAQ(index)}
              className="w-full flex justify-between items-center p-4 text-left text-lg font-medium text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none border-b rounded-t-lg"
            >
              <span>{index + 1}. {faq.question}</span>
              <svg
                className={`w-5 h-5 transform transition-transform ${activeIndex === index ? 'rotate-180' : ''}`}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
              </svg>
            </button>
            {activeIndex === index && (
              <div className="p-4 text-gray-600 bg-white border-t">
                {faq.answer}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
    </div>
  );
};

export default SupportLayout;


