import React from 'react';
import drainageImg from '../../assets/drainage-1.svg';
import waterPumpImg from '../../assets/water-pump-1.svg';
import iconImg from '../../assets/icon.svg';
import cardImg from '../../assets/card.svg';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { LineChart, Line ,AreaChart, Area} from 'recharts';



import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useState } from 'react';


const CustomHorizontalLines = (props) => {
  const { yAxis, width, height } = props;
  const data = yAxis ? yAxis.getTicks() : [];
  
  return (
    <g>
      {data.map((entry) => (
        <line
          key={`line-${entry.value}`}
          x1={0}
          y1={yAxis.toGlobalCoordinate(entry.value)}
          x2={width}
          y2={yAxis.toGlobalCoordinate(entry.value)}
          stroke="#ddd"
          strokeWidth={1}
        />
      ))}
    </g>
  );
};


const CustomGridLines = ({ x, y, width, height }) => {
  const ticks = [10, 20, 30, 40, 50]; // Customize based on your Y-axis scale

  return (
    <g>
      {ticks.map((tick) => (
        <line
          key={tick}
          x1={0}
          y1={y(tick)}
          x2={width}
          y2={y(tick)}
          stroke="#ddd"
          strokeDasharray="3 3"
        />
      ))}
    </g>
  );
};


const AnalyticsLayout = () => {

    const data = [
        { month: 'Jan-24', value: 120 },
        { month: 'Feb-24', value: 60 },
        { month: 'Mar-24', value: 90 },
        { month: 'Apr-24', value: 30 },
        { month: 'May-24', value: 90 },
        { month: 'Jun-24', value: 60 },
        { month: 'Jul-24', value: 60 },
        { month: 'Aug-24', value: 30 },
        { month: 'Sep-24', value: 0 },
        { month: 'Oct-24', value: 90 },
        { month: 'Nov-24', value: 60 },
        { month: 'Dec-24', value: 60 },
      ];
      const data1= [
        { month: 'Jun-23', hours: 5 },
        { month: 'Jul-23', hours: 6 },
        { month: 'Aug-23', hours: 8 },
        { month: 'Sep-23', hours: 7 },
        { month: 'Oct-23', hours: 10 },
        { month: 'Nov-23', hours: 5 },
        { month: 'Dec-23', hours: 12 },
        { month: 'Jan-24', hours: 15 },
        { month: 'Feb-24', hours: 8 },
        { month: 'Mar-24', hours: 10 },
        { month: 'Apr-24', hours: 6 },
        { month: 'May-24', hours: 15 },
      ];
      const data2 = [
        { month: 'Jun-23', flowRate: 5 },
        { month: 'Jul-23', flowRate: 6 },
        { month: 'Aug-23', flowRate: 8 },
        { month: 'Sep-23', flowRate: 7 },
        { month: 'Oct-23', flowRate: 10 },
        { month: 'Nov-23', flowRate: 5 },
        { month: 'Dec-23', flowRate: 12 },
        { month: 'Jan-24', flowRate: 15 },
        { month: 'Feb-24', flowRate: 8 },
        { month: 'Mar-24', flowRate: 10 },
        { month: 'Apr-24', flowRate: 6 },
        { month: 'May-24', flowRate: 15 },
      ];
      




      // Sample data
const rowData = [
    { startEndDate: '1 Mar 2024 - 1 Apr 2024', workHours: '17 hr 3 min', flowRate: 136.76, consumption: 2332.51, totalizer: 267332.51 ,reverse_flow:0},
    { startEndDate: '1 Mar 2024 - 1 Apr 2024', workHours: '17 hr 3 min', flowRate: 136.76, consumption: 2332.51, totalizer: 267332.51 ,reverse_flow:0},
    { startEndDate: '1 Mar 2024 - 1 Apr 2024', workHours: '17 hr 3 min', flowRate: 136.76, consumption: 2332.51, totalizer: 267332.51,reverse_flow:0},
    { startEndDate: '1 Mar 2024 - 1 Apr 2024', workHours: '17 hr 3 min', flowRate: 136.76, consumption: 2332.51, totalizer: 267332.51 ,reverse_flow:0},
    { startEndDate: '1 Mar 2024 - 1 Apr 2024', workHours: '17 hr 3 min', flowRate: 136.76, consumption: 2332.51, totalizer: 267332.51 ,reverse_flow:0},
    { startEndDate: '1 Mar 2024 - 1 Apr 2024', workHours: '17 hr 3 min', flowRate: 136.76, consumption: 2332.51, totalizer: 267332.51 ,reverse_flow:0},
    { startEndDate: '1 Mar 2024 - 1 Apr 2024', workHours: '17 hr 3 min', flowRate: 136.76, consumption: 2332.51, totalizer: 267332.51 ,reverse_flow:0},
    { startEndDate: '1 Mar 2024 - 1 Apr 2024', workHours: '17 hr 3 min', flowRate: 136.76, consumption: 2332.51, totalizer: 267332.51,reverse_flow:0},
  ];
  
   
  const columnDefs = [
    { headerName: 'Start - End Date', field: 'startEndDate', sortable: false, filter: false, cellStyle: { fontWeight: 'bold', color: '#202224', textAlign: 'left' }, headerStyle: { fontWeight: 'bold', color: '#202224', textAlign: 'left' }},
    { headerName: 'Work Hours', field: 'workHours', sortable: false, filter: false, cellStyle: { fontWeight: 'bold', color: '#202224', textAlign: 'left' }, headerStyle: { fontWeight: 'bold', color: '#202224', textAlign: 'left' }},
    { headerName: 'Flow Rate (kL/hr)', field: 'flowRate', sortable: false, filter: false, cellStyle: { fontWeight: 'bold', color: '#202224', textAlign: 'left' }, headerStyle: { fontWeight: 'bold', color: '#202224', textAlign: 'left' }},
    { headerName: 'Consumption (kL)', field: 'consumption', sortable: false, filter: false, cellStyle: { fontWeight: 'bold', color: '#202224', textAlign: 'left' }, headerStyle: { fontWeight: 'bold', color: '#202224', textAlign: 'left' }},
    { headerName: 'Totalizer (kL)', field: 'totalizer', sortable: false, filter: false, cellStyle: { fontWeight: 'bold', color: '#202224', textAlign: 'left' }, headerStyle: { fontWeight: 'bold', color: '#202224', textAlign: 'left' }},
    { headerName: 'Reverse flow (kL)', field: 'reverse_flow', sortable: false, filter: false, cellStyle: { fontWeight: 'bold', color: '#202224', textAlign: 'left' }, headerStyle: { fontWeight: 'bold', color: '#202224', textAlign: 'left' }},
  
  
  ];

  const CustomDropdown = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('Plant 1');
  
    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };
  
    const selectOption = (option) => {
      setSelectedOption(option);
      setIsOpen(false);
    };
  
    return (
      <div className="relative inline-block w-full">
        {/* Custom Dropdown Button */}
        <div
          className="block w-full bg-white border border-gray-300 font-extrabold hover:border-gray-500 px-4 py-3 pr-8 lg:text-lg sm:text-xxs rounded-full shadow leading-tight cursor-pointer"
          onClick={toggleDropdown}
        >
          {selectedOption}
        </div>
  
        {/* Dropdown List */}
        {isOpen && (
          <ul className="absolute z-10 w-full bg-white border border-gray-300 rounded-md shadow-lg max-h-48 overflow-auto">
            <li
              className="px-4 py-2  lg:text-lg sm:text-xxs hover:bg-bluetextcolor cursor-pointer"
              onClick={() => selectOption('Plant 1')}
            >
              Plant 1
            </li>
            <li
              className="px-4 py-2 lg:text-lg sm:text-xxs hover:bg-bluetextcolor cursor-pointer"
              onClick={() => selectOption('Plant 2')}
            >
              Plant 2
            </li>
            <li
              className="px-4 py-2 lg:text-lg sm:text-xxs hover:bg-bluetextcolor cursor-pointer"
              onClick={() => selectOption('Plant 3')}
            >
              Plant 3
            </li>
          </ul>
        )}
      </div>
    );
  };

  const CustomDropdowns = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('All Meters');
  
    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };
  
    const selectOption = (option) => {
      setSelectedOption(option);
      setIsOpen(false);
    };
  
    return (
      <div className="relative inline-block w-full">
        {/* Custom Dropdown Button */}
        <div
          className="block w-full bg-white border border-gray-300 font-extrabold hover:border-gray-500   px-4 py-3 pr-8 lg:text-lg sm:text-xxs rounded-full shadow leading-tight cursor-pointer"
          onClick={toggleDropdown}
        >
          {selectedOption}
        </div>
  
        {/* Dropdown List */}
        {isOpen && (
          <ul className="absolute z-10 lg:text-lg sm:text-xxs w-full bg-white  border border-gray-300 rounded-md shadow-lg max-h-48 overflow-auto">
            <li
              className=" lg:px-4 lg:py-2 px-4 py-2  lg:text-lg sm:text-xxs hover:bg-bluetextcolor cursor-pointer"
              onClick={() => selectOption('Meter 1')}
            >
              Meter 1
            </li>
            <li
              className=" lg:px-4 lg:py-2 px-4 py-2  lg:text-lg sm:text-xxs  hover:bg-bluetextcolor cursor-pointer"
              onClick={() => selectOption('Meter 2')}
            >
              Meter 2
            </li>
            <li
              className=" lg:px-4 lg:py-2 px-4 py-2  lg:text-lg sm:text-xxs hover:bg-bluetextcolor cursor-pointer"
              onClick={() => selectOption('Meter 3')}
            >
              Meter 3
            </li>
          </ul>
        )}
      </div>
    );
  };
  const CustomDropdownss = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('Last 12 Months');
  
    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };
  
    const selectOption = (option) => {
      setSelectedOption(option);
      setIsOpen(false);
    };
  
    return (
      <div className="relative inline-block w-full">
        {/* Custom Dropdown Button */}
        <div
          className="block w-full bg-white border border-gray-300 font-extrabold hover:border-gray-500 px-4 py-3 pr-8 rounded-full shadow leading-tight cursor-pointer"
          onClick={toggleDropdown}
        >
          {selectedOption}
        </div>
  
        {/* Dropdown List */}
        {isOpen && (
          <ul className="absolute z-10 w-full bg-white border border-gray-300 rounded-md shadow-lg max-h-48 overflow-auto">
            <li
              className="px-4 py-2 hover:bg-bluetextcolor cursor-pointer"
              onClick={() => selectOption('Last 12 months')}
            >
              Last 12 months
            </li>
            <li
              className="px-4 py-2 hover:bg-bluetextcolor cursor-pointer"
              onClick={() => selectOption('Last 6 months')}
            >
            Last 6 months
            </li>
            <li
              className="px-4 py-2 hover:bg-bluetextcolor cursor-pointer"
              onClick={() => selectOption('Last 3 months')}
            >
             Last 3 months
            </li>
          </ul>
        )}
      </div>
    );
  };
  return (
    <div
      className="lg:p-6 sm:p-0  flex flex-col space-y-4 "
      style={{ backgroundColor: "#F5F6FA" }}
    >
      {/* meter dashboard   */}
      <div className=" flex flex-col items-center dashbpaddings  pb-0">
        <div className="w-full  p-2">
          <div className="flex justify-between items-center">
            <h2 className="text-xl lg:text-3xl sm:text-xl font-bold text-gray-900">
              Analytics
            </h2>
            <div className="flex space-x-4">
              <div className="relative inline-block text-left">
                <CustomDropdown />
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.415 0.292496L6 4.8775L10.585 0.292496L12 1.7075L6 7.7075L0 1.7075L1.415 0.292496Z"
                      fill="black"
                    />
                  </svg>
                </div>
              </div>
              <div className="relative inline-block text-left">
                <CustomDropdowns />
                {/*   <select className="block appearance-none w-full bg-white border border-gray-300 font-extrabold hover:border-gray-500 px-4 py-3 pr-8 rounded-full shadow leading-tight focus:outline-none focus:shadow-outline">
                <option>All Meters</option>
                <option>Meter 1</option>
                <option>Meter 2</option>
              </select> */}
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.415 0.292496L6 4.8775L10.585 0.292496L12 1.7075L6 7.7075L0 1.7075L1.415 0.292496Z"
                      fill="black"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center p-4 md:p-4 pt-0 mt-0 pb-0">
        <div className="bg-white rounded-lg p-1 md:p-6 pb-0 shadow-lg  w-full">
          <div className="flex flex-row md:flex-row justify-between items-start md:items-start">
            <div className="text-gray-700 flex flex-row md:flex-row md:items-start md:items-start mb-0 md:mb-4">
              <span className="font-semibold text-xxs md:text-xl">
                July, 2024
              </span>
              <span className="ml-2 text-xxs md:text-base">
                | 01 July, 08:00 AM - 16 July, 08:00 AM
              </span>
            </div>
          </div>
          <div className="flex flex-row md:flex-row justify-between items-center md:items-start">
            <div className="w-1/3 md:w-1/3 mb-4 md:mb-0 text-left">
              <div className="text-xl md:text-6xl font-bold text-blue-900 mt-2">
                935.9 KL
              </div>
              <div className="flex items-center mt-2 bg-green-100 border border-green-600 text-green-800 rounded-md px-1 md:px-4 pl-1 w-full md:w-max">
                <img
                  src={iconImg}
                  alt="Icon"
                  className="w-1 h-1 md:w-4 md:h-4  mr-1"
                />
                <span className="font-medium text-sxsx md:text-base">9%</span>
                <span className="ml-2 text-xxs md:text-sm text-black">
                  34 KL more From Last Month
                </span>
              </div>
            </div>

            <div className="w-1/3 md:w-1/3 flex flex-col md:flex-row justify-between items-center md:items-center space-y-4 md:space-y-0 md:space-x-8">
              <div className="flex flex-col md:flex-row sm:flex-col items-center">
                <img
                  src={waterPumpImg}
                  alt="Water Pump"
                  className="w-12 h-12 md:w-36 md:h-36 mr-4"
                />
                <div>
                  <div className="text-xxs md:text-sm text-left text-gray-500">
                    Pump Working Hours
                  </div>
                  <div className="text-xxs md:text-lg text-left font-semibold text-gray-900">
                    1 Hour 54 Mins
                  </div>
                </div>
              </div>
            </div>

            <div className="w-1/3 md:w-1/3 flex flex-col md:flex-row justify-between items-center md:items-center space-y-4 md:space-y-0 md:space-x-8">
              <div className="flex flex-col md:flex-row sm:flex-col items-center">
                <img
                  src={drainageImg}
                  alt="Drainage"
                  className="w-12 h-12 md:w-36 md:h-36 mr-4"
                />
                <div>
                  <div className="text-xxs md:text-sm text-left text-gray-500">
                    Average Flow Rate
                  </div>
                  <div className="text-xxs md:text-lg text-left font-semibold text-gray-900">
                    1.3 M³/S
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Bar charts   */}
      <div className="p-4 pt-0   flex flex-col items-center">
        <div className="w-full ">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl md:text-3xl font-bold text-gray-900">
              Monthly Report
            </h2>
            <div className="relative inline-block text-left">
              <CustomDropdownss />
              {/*    <select className="block appearance-none w-full bg-white border border-gray-300 font-normal hover:border-gray-500 px-4 py-3 pr-8 rounded-full shadow leading-tight focus:outline-none focus:shadow-outline">
                <option>Last 12 months</option>
                <option>Last 6 months</option>
                <option>Last 3 months</option>
              </select> */}
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  width="12"
                  height="8"
                  viewBox="0 0 12 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.415 0.292496L6 4.8775L10.585 0.292496L12 1.7075L6 7.7075L0 1.7075L1.415 0.292496Z"
                    fill="black"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full  bg-white rounded-lg shadow-lg p-6">
          {/*  <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold text-gray-900">Monthly Report</h2>
          <button className="flex items-center bg-gray-200 text-gray-700 px-4 py-2 rounded-md">
            Last 12 Months
            <span className="ml-2">▼</span>
          </button>
        </div> */}
          <div className="overflow-x-auto">
            <div className="w-full">
              <div className="text-left mb-4">
                <div className="text-lg font-semibold text-gray-700">
                  Consumption (KL)
                </div>
              </div>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart
                  data={data}
                  margin={{ top: 20, right: 30, left: -10, bottom: 50 }} // Set left margin to 0
                >
                  <CartesianGrid
                    vertical={false} // Hide vertical lines
                    horizontal={false} // Hide horizontal lines
                  />
                  <XAxis
                    dataKey="month"
                    angle={-45}
                    textAnchor="end"
                    dy={10}
                    style={{ fontSize: "12px", fontWeight: "600" }}
                  />
                  <YAxis style={{ fontSize: "12px", fontWeight: "600" }} />
                  <Tooltip />
                  <Bar dataKey="value" fill="#2B3C73" />
                  <CustomHorizontalLines />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>

      {/* line charts   */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div className="p-4 flex flex-col items-center">
          <div className="w-full bg-white rounded-lg shadow-lg p-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold text-gray-900">Work Hours</h2>
            </div>
            <div className="overflow-x-auto">
              <div className="w-full">
                <ResponsiveContainer width="100%" height={200}>
                  <LineChart
                    data={data1}
                    margin={{ top: 20, right: 30, left: -20, bottom: 50 }}
                  >
                    <CartesianGrid
                      horizontal={true}
                      vertical={false}
                      strokeDasharray="3 3"
                    />
                    <XAxis
                      dataKey="month"
                      angle={-45}
                      textAnchor="end"
                      dy={10}
                      style={{ fontSize: "12px", fontWeight: "600" }}
                    />
                    <YAxis style={{ fontSize: "12px", fontWeight: "600" }} />
                    <Tooltip />
                    <Line
                      type="monotone"
                      dataKey="hours"
                      stroke="#EDA10D"
                      strokeWidth={3}
                      dot={{ r: 5 }}
                      activeDot={{ r: 8 }}
                    />
                    <CustomGridLines />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>

        <div className="p-4 flex flex-col items-center">
          <div className="w-full bg-white rounded-lg shadow-lg p-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold text-gray-900">Flow Rate</h2>
            </div>
            <div className="overflow-x-auto">
              <div className="w-full">
                <ResponsiveContainer width="100%" height={200}>
                  <AreaChart
                    data={data2}
                    margin={{ top: 20, right: 30, left: -20, bottom: 50 }}
                  >
                    <defs>
                      <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop
                          offset="5%"
                          stopColor="#2B3C73"
                          stopOpacity={0.8}
                        />
                        <stop
                          offset="95%"
                          stopColor="#2B3C73"
                          stopOpacity={0}
                        />
                      </linearGradient>
                    </defs>
                    <CartesianGrid
                      horizontal={true}
                      vertical={false}
                      strokeDasharray="3 3"
                    />
                    <XAxis
                      dataKey="month"
                      angle={-45}
                      textAnchor="end"
                      dy={10}
                      style={{ fontSize: "12px", fontWeight: "600" }}
                    />
                    <YAxis style={{ fontSize: "12px", fontWeight: "600" }} />
                    <Tooltip />
                    <Area
                      type="monotone"
                      dataKey="flowRate"
                      stroke="#2B3C73"
                      fillOpacity={1}
                      fill="url(#colorUv)"
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* AG Grid   */}

      <div className="p-4 pt-0 flex flex-col items-center">
        <div className="w-full rounded-2xl shadow-2xl overflow-hidden">
          <div
            className="ag-theme-alpine"
            style={{
              height: 400,
              width: "100%",
              borderRadius: "18px",
              border: "1px solid #ccc", // Apply border radius
              overflow: "hidden", // Prevent overflow
            }}
          >
            <AgGridReact
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={{ flex: 1, minWidth: 150 }}
              pagination={true}
              paginationPageSize={8}
              gridOptions={{
                suppressCellSelection: true,
                suppressMovableColumns: true, // Disable column drag and drop
              }}
              suppressHorizontalScroll={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsLayout;


