// store.js
import { createStore } from 'redux';
import rootReducer from '../redux/reducers/Auth-reducers';

const initialState = {
  auth: {
    isAuthenticated: localStorage.getItem('isAuthenticated') === 'true',
    accessToken: localStorage.getItem('accessToken') || null,
    error: null
  }
};

const store = createStore(
  rootReducer,
  initialState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
