import React from 'react';

const notifications = [
  { title: 'System Alarms', items: ['Compliance Limit', 'Internal Limit', 'Above Last Day', 'Above Last Month'] },
  { title: 'SMS Notifications', items: ['Compliance Limit', 'Internal Limit', 'Above Last Day', 'Above Last Month'] },
  { title: 'Email Notifications', items: ['Compliance Limit', 'Internal Limit', 'Above Last Day', 'Above Last Month'] }
];

const Notifications = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-4">
  {notifications.map((notification, index) => (
    <div key={index} className="bg-white p-4 rounded-lg shadow">
      <h3 className="text-sm font-bold bgprima mb-4 bb">{notification.title}</h3>
      <ul className="grid grid-cols-2 gap-4 md:grid-cols-1">
        {notification.items.map((item, idx) => (
          <li key={idx} className="flex items-center space-x-4 bb">
            <label className="relative inline-flex items-center cursor-pointer">
              <input type="checkbox" className="sr-only peer" />
              <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 peer-checked:bg-yellow-400 after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:after:translate-x-full peer-checked:after:border-white"></div>
            </label>
            <span className="text-gray-700">{item}</span>
          </li>
        ))}
      </ul>
    </div>
  ))}
</div>
  );
}

export default Notifications;
