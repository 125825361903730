import logo from './logo.svg';
import './App.css';
//import Login from './components/Login';
import Dashboard from './components/layout/Dashboard';
import Sidebar from './components/layout/Sidebar';
import Header from './components/layout/Header';
import AnalyticsLayout from './components/layout/AnalyticsLayout';
import MetersLayout from './components/layout/MetersLayout';
import MeterDetails from './components/layout/MeterDetails';
import ReportLayout from './components/layout/ReportLayout';

import SettingsLayout from './components/layout/SettingsLayout';
import SupportLayout from './components/layout/SupportLayout';

import AlaramLayout from './components/layout/AlaramLayout';
import { useState } from 'react';
import { Routes, Route, Navigate, useLocation, Redirect } from 'react-router-dom';
import Layout from "./components/layouts/Layout";

//import NoPage from "./components/404";
import { useEffect } from "react";
import { HSStaticMethods } from "preline/preline";
/* import MyPlan from "./components/Pages/Myplan/index";
import Product from "./components/Pages/Product/index";
import WaterAnalytics from "./components/Pages/waterAnalytics/index";
import Home from "./components/Pages/Home/Home"; */
import Login from "./components/pages/login/index";
import { useSelector } from 'react-redux';
//import OfflineMessage from './components/OfflinePage';

const RequireAuth = ({ element, ...rest }) => {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  return isAuthenticated ? (
    element
  ) : (
    <Navigate to="/login" replace />
  );
};


const App = () => {

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);
  const location = useLocation();

  useEffect(() => {
    require("preline/preline");

  }, []);

  useEffect(() => {
    // @ts-ignore
    HSStaticMethods.autoInit();
  }, [location.pathname]);
  const accessToken = localStorage.getItem("accessToken");
  //console.log("accessToken", accessToken)
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  return (
    <>
      {isOnline ? (<Routes>

        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={!isAuthenticated ? (<Login />) : (<Navigate to="/dashboard" replace />)} />
        <Route
          path="/"
          element={isAuthenticated ? (<Layout /> || <Navigate to="/dashboard" replace />) : (<Navigate to="/login" replace />)}
        >

          <Route path="/analytics" element={<RequireAuth element={<AnalyticsLayout />} />} />
          <Route path="/meter-details" element={<RequireAuth element={<MeterDetails />} />} />
          <Route path="/dashboard" element={<RequireAuth element={<Dashboard />} />} />
          <Route path="/reports" element={<RequireAuth element={<ReportLayout />} />} />
          <Route path="/settings" element={<RequireAuth element={<SettingsLayout />} />} />
          <Route path="/support" element={<RequireAuth element={<SupportLayout />} />} />
           
          <Route path="/alarams" element={<RequireAuth element={<AlaramLayout />} />} />
           <Route
            path="/meters"
            element={<RequireAuth element={<MetersLayout />} />}
          />
          <Route path="*" element={<Login />} />
        </Route>


      </Routes>)
        : (
          <Login />
        )}
    </>
  );
};

export default App;
/* 
function App() {
  return (
    <div className="App">
     
        <MeterDetails />
        <MetersLayout/>  <Dashboard/>
      
     
      <div className="flex">
      <Sidebar />
      <div className="flex flex-col w-full">
        <Header />
     <AnalyticsLayout/>
      </div>
    </div> 
    </div>
  );
}

export default App;
 */