import React, { useState } from 'react';
import drainageImg from '../../assets/drainage-1.svg';
import waterPumpImg from '../../assets/water-pump-1.svg';
import iconImg from '../../assets/icon.svg';
import cardImg from '../../assets/card.svg';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { LineChart, Line ,AreaChart, Area} from 'recharts';

const SettingsLayout = () => {

    const [activeTab, setActiveTab] = useState('EditProfile');

    const renderContent = () => {
      switch (activeTab) {
        case 'EditProfile':
            return (
                <div className="text-center">
                  <div className="mb-6 flex justify-center">
                    <img className="rounded-full h-24 w-24" src="https://via.placeholder.com/96" alt="profile" />
                  </div>
                  <div className="grid grid-cols-2 gap-6 px-0 lg:px-32">
                    <div>
                      <label className="block text-left text-gray-600 mb-2">Full Name</label>
                      <input
                        className="w-full p-3 border rounded-md bg-gray-100 text-gray-500"
                        type="text"
                        value="Mani Roy"
                        disabled
                      />
                    </div>
                    <div>
                      <label className="block text-left text-gray-600 mb-2">User Id</label>
                      <input
                        className="w-full p-3 border rounded-md"
                        type="text"
                        placeholder="Enter your last name"
                      />
                    </div>
                    <div>
                      <label className="block text-left text-gray-600 mb-2">Email Id</label>
                      <input
                        className="w-full p-3 border rounded-md bg-gray-100 text-gray-500"
                        type="text"
                        value="maniroy4532@gmail.com"
                        disabled
                      />
                    </div>
                    <div>
                      <label className="block text-left text-gray-600 mb-2">Phone Number</label>
                      <input
                        className="w-full p-3 border rounded-md"
                        type="text"
                        placeholder="+91 9899999999"
                      />
                    </div>
                    <div>
                      <label className="block text-left text-gray-600 mb-2">Current Password</label>
                      <input
                        className="w-full p-3 border rounded-md bg-gray-100 text-gray-500"
                        type="text"
                        value="mani@123"
                        disabled
                      />
                    </div>
                    <div>
                      <label className="block text-left text-gray-600 mb-2">New Password</label>
                      <input
                        className="w-full p-3 border rounded-md"
                        type="text"
                        placeholder="maniroy@123"
                      />
                    </div>
                  </div>
                </div>
              );
        case 'MeterSettings':
          return (
            <div className="grid grid-cols-2 gap-6 px-0 lg:px-32 pt-10">
              <div>
                <label className="block text-gray-600 mb-2">Current Unit</label>
                <input className="w-full p-3 border rounded-md bg-gray-100 text-gray-500" type="text" value="kL/hr" disabled />
              </div>
              <div>
                <label className="block text-gray-600 mb-2">Update NOC Limit</label>
                <select className="w-full p-3 border rounded-md">
                  <option>kL/hr</option>
                </select>
              </div>
              <div>
                <label className="block text-gray-600 mb-2">Current NOC Limit</label>
                <input className="w-full p-3 border rounded-md bg-gray-100 text-gray-500" type="text" value="1280 kL" disabled />
              </div>
              <div className="flex space-x-4 items-end">
                <div className="w-full">
                  <label className="block text-gray-600 mb-2">Update NOC Limit</label>
                  <input className="w-full p-3 border rounded-md" type="text" placeholder="Update New" />
                </div>
                <select className="p-3 border rounded-md">
                  <option>All Meters</option>
                </select>
              </div>
              <div>
                <label className="block text-gray-600 mb-2">Current Internal Limit</label>
                <input className="w-full p-3 border rounded-md bg-gray-100 text-gray-500" type="text" value="980 kL" disabled />
              </div>
              <div className="flex space-x-4 items-end">
                <div className="w-full">
                  <label className="block text-gray-600 mb-2">Update Internal Limit</label>
                  <input className="w-full p-3 border rounded-md" type="text" placeholder="Update New" />
                </div>
                <select className="p-3 border rounded-md">
                  <option>All Meters</option>
                </select>
              </div>
            </div>
          );
        case 'RequestAPI':
            return (
                <div >
                  <div className="mb-6">
                    <label className="block text-gray-600 mb-2">API Type</label>
                    <input
                      className="w-full p-3 border rounded-md bg-gray-100 text-gray-500"
                      type="text"
                      value="Analytics on Quarterly basis"
                      disabled
                    />
                  </div>
                  <div className="mb-6">
                    <label className="block text-gray-600 mb-2">Describe in Details</label>
                    <textarea
                      className="w-full p-3 border rounded-md bg-gray-100 text-gray-500"
                      rows="4"
                      disabled
                    >With Daily and Monthly Analytics, I want to see Consumption in Analytics on Quarterly basis</textarea>
                  </div>
            
                </div>
              );
        default:
          return null;
      }
    };
    
    
    



   
  
  return (
    <div className="p-2 lg:p-6 flex flex-col space-y-4" style={{backgroundColor:'#F5F6FA'}}>
{/* meter dashboard   */}
<div className=" flex flex-col items-center meterpadding ">
      <div className="w-full mx-auto">
        <div className="flex justify-between items-center">
          <h2 className="text-3xl font-bold text-gray-900">Settings</h2>
          <div className="flex space-x-4">
  <div className="relative inline-flex items-center text-left space-x-2">
    <button className="bg-yellow-400 text-black font-semibold px-1 py-0 lg:px-4 lg:py-2 rounded-full hover:bg-yellow-500">
      Request Calibration
    </button>
    <button className="border border-blue-500 primarycolor font-semibold px-1 py-0 lg:px-4 lg:py-2 rounded-full hover:bg-blue-100">
      Ask Your Query?
    </button>
  </div>
</div>
        </div>
      </div>
    </div>


    <div className="p-2 lg:p-4  bg-white rounded-lg shadow-md mt-0 ml-2 lg:ml-6 mr-2">
    
      <div className="mb-6 border-b">
      <nav className="flex space-x-8">
          <button
            className={`pb-4 border-b-2 ${
              activeTab === 'EditProfile' ? ' textprimary' : 'border-transparent text-gray-600'
            }`}
            onClick={() => setActiveTab('EditProfile')}
          >
            Edit Profile
          </button>
          <button
            className={`pb-4 border-b-2 ${
              activeTab === 'MeterSettings' ? ' textprimary' : 'border-transparent text-gray-600'
            }`}
            onClick={() => setActiveTab('MeterSettings')}
          >
            Meter Settings
          </button>
          <button
            className={`pb-4 border-b-2 ${
              activeTab === 'RequestAPI' ? ' textprimary' : 'border-transparent text-gray-600'
            }`}
            onClick={() => setActiveTab('RequestAPI')}
          >
            Request API
          </button>
        </nav>
      </div>
      {renderContent()}
      <div className="mt-6 text-center">
        <button className="settingsbuttom px-8 py-3 rounded-full hover:bg-yellow-500">
          Save Changes
        </button>
      </div>
    </div>
    </div>
  );
};

export default SettingsLayout;


