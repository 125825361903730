import React, { useState } from 'react';
import Modal from "react-responsive-modal";
import axios from 'axios';
import toast from 'react-hot-toast';
import API_URL from '../../Environment/environment';

const SubmitQueryModal = ({ open, onClose }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [comments, setComments] = useState('');
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(null);

  const validate = () => {
    const newErrors = {};
    if (!name) newErrors.name = 'Name is required.';
    if (!email) {
      newErrors.email = 'Email is required.';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      newErrors.email = 'Email is invalid.';
    }
    if (!comments) newErrors.comments = 'Comments are required.';
    // toast.error(newErrors)
    return newErrors;
  };

  const handleSubmit = async () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      const response = await axios.post(`${API_URL}/request/query/`, {
        name,
        email,
        comments,
      });
      setSuccess('Your query has been submitted successfully.');
      setErrors({});
      setName('');
      setEmail('');
      setComments('');
      toast.success(response?.data?.message)
    } catch (error) {
      const serverErrorMessage = error.response?.data?.message || 'An error occurred while submitting your query.';
      setErrors({ submit: serverErrorMessage });
      toast.error(error.response?.data?.message)

      setSuccess(null);
    }
  };

  return (
    <Modal open={open} onClose={onClose} className="customModal" center>
      <div>
        <h2 className="mr-8 mb-4 font-bold">Submit your query</h2>
        <div className="pb-3 flex flex-col gap-2">
          <label className="text-sm font-medium text-gray-700 dark:text-neutral-400">
            Name
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500"
              placeholder="Name"
            />
            {errors.name && <span className="text-red-500 text-xs">{errors.name}</span>}
          </label>
          <label className="text-sm font-medium text-gray-700 dark:text-neutral-400">
            Email
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500"
              placeholder="Email"
            />
            {errors.email && <span className="text-red-500 text-xs">{errors.email}</span>}
          </label>
          <label className="text-sm font-medium text-gray-700 dark:text-neutral-400">
            Comments
            <textarea
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              className="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500"
              placeholder="Comments"
              rows="4"
            />
            {errors.comments && <span className="text-red-500 text-xs">{errors.comments}</span>}
          </label>
        </div>
        {errors.submit && <div className="text-red-500 text-sm mb-2">{errors.submit}</div>}
        {success && <div className="text-green-500 text-sm mb-2">{success}</div>}
        <div className="flex justify-end gap-2 text-sm">
          <button onClick={onClose} className="py-1 px-2 rounded-md border">
            Cancel
          </button>
          <button onClick={handleSubmit} className="py-1 px-2 rounded-md bg-yellow-500">
            Submit
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default SubmitQueryModal;
